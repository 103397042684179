import firebase from "firebase/app";
import React, { useEffect, useState } from "react";
import { firebaseFunctions } from "../firebase";
import { SplashScreen } from "../components/Introduction/SplashScreen";
import { UserInformation } from "../Service/User";

export const AuthContext = React.createContext<AuthContextObject | null>(null);

interface AuthContextObject {
    currentUser?: firebase.User | null;
    loading?: boolean;
    userInfo?: UserInformation;
    updateUser: (user: firebase.User) => void;
}

export const AuthProvider = (props: { children: any }) => {
    const [currentContext, setCurrentContext] =
        useState<AuthContextObject | null>(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (!user) {
                setCurrentContext({
                    currentUser: null,
                    updateUser: updateUser,
                });
                setLoading(false);
                return;
            }
            updateUser(user).finally(() => setLoading(false));
        });
    }, []);

    async function updateUser(user: firebase.User) {
        setCurrentContext({ loading: true, updateUser: updateUser });

        await firebaseFunctions
            .httpsCallable("users-getUserFromAuthUid")({
                authUid: user.uid,
            })
            .then((res) => {
                setCurrentContext({
                    currentUser: user,
                    userInfo: res.data,
                    updateUser: updateUser,
                });
            })
            .catch(() => {
                setCurrentContext({
                    currentUser: user,
                    updateUser: updateUser,
                });
            });
    }

    if (loading) return <SplashScreen />;

    return (
        <AuthContext.Provider value={currentContext}>
            {props.children}
        </AuthContext.Provider>
    );
};
