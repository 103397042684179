import firebase from "firebase/app";
import React, { useContext, useEffect, useState } from "react";
import { View, Text, StyleSheet, Image, Platform } from "react-native";
import { FullScreenBackground } from "../UI/UI";
import * as Google from "expo-auth-session/providers/google";
import * as WebBrowser from "expo-web-browser";
import { useNavigation, useRoute } from "@react-navigation/core";
import { RouteProp } from "@react-navigation/native";
import { ColorButton } from "../UI/ColorButton";
import { blue, white } from "../../Colors";
import { CardWithBigBot } from "../UI/Card";
import { TextAnimator } from "../UI/TextAnimator";
import { KidBasicInfo } from "../../Service/User";
import { AuthContext } from "../../provider/Auth";
import { Loading } from "../UI/Loading";
import { ActivityIndicator } from "react-native-paper";
import { logInAsync, LogInResult } from "expo-google-app-auth";

WebBrowser.maybeCompleteAuthSession();

export const SignIn = () => {
    const [loading, setLoading] = useState(false);
    const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
        expoClientId:
            "648663652774-j788etjek7ipjmgvu4v5nbrld1d2gh5o.apps.googleusercontent.com",
        androidClientId:
            "648663652774-u7sl2hdjflq8l5s6afr6schpsp61ci2c.apps.googleusercontent.com",
        webClientId:
            "648663652774-40g3706tiu52gceq3metgg42pcakq0fc.apps.googleusercontent.com",
        iosClientId:
            "648663652774-o8bv45vor12brocdfmeg28l0gi27nce8.apps.googleusercontent.com",
        selectAccount: true,
    });
    const navigation = useNavigation();
    const authContext = useContext(AuthContext);
    useEffect(() => {
        if (response?.type === "success") {
            const { id_token } = response.params;
            setLoading(true);

            const credential =
                firebase.auth.GoogleAuthProvider.credential(id_token);

            firebase.auth().signInWithCredential(credential);
        }
    }, [response]);

    useEffect(() => {
        if (authContext?.currentUser && !authContext.userInfo?.userId)
            navigation.navigate("RegisterChildren");
    }, [authContext]);

    /** Android is bugeed and does not work with the common method */
    const temporaryPromptAsyncFix = () => {
        if (Platform.OS === "android") {
            androidLogin();
            return;
        }
        promptAsync();
    };

    const androidLogin = () => {
        logInAsync({
            androidStandaloneAppClientId:
                "648663652774-u7sl2hdjflq8l5s6afr6schpsp61ci2c.apps.googleusercontent.com",
            behavior: "web",
            clientId:
                "648663652774-u7sl2hdjflq8l5s6afr6schpsp61ci2c.apps.googleusercontent.com",
        }).then((res) => {
            if (res.type === "success") {
                const credential = firebase.auth.GoogleAuthProvider.credential(
                    res.idToken
                );
                setLoading(true);
                firebase.auth().signInWithCredential(credential);
            }
        });
    };

    return (
        <FullScreenBackground
            source={require("../../../assets/backgrounds/children.jpg")}
        >
            <CardWithBigBot childrenStyle={styles.rightElementsContainer}>
                <TextAnimator
                    style={styles.explanationTextContainer}
                    textStyle={styles.explanationText}
                >
                    Per tal que puguis rebre informes del que fan o poder
                    iniciar sessió en un altre dispositiu, has d'identificar-te
                    amb Google. No t'amoiïnis, si vols, després podràs afegir
                    altres mètodes!
                </TextAnimator>
                {loading ? <ActivityIndicator color={blue} /> : undefined}

                <ColorButton
                    text="Continua"
                    bColor={blue}
                    tColor={white}
                    centerText
                    onPress={() => temporaryPromptAsyncFix()}
                    style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingTop: 10,
                        paddingBottom: 10,
                    }}
                />
            </CardWithBigBot>
        </FullScreenBackground>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    card: {
        width: "70%",
        height: "70%",
        backgroundColor: "white",
        borderRadius: 50,
        display: "flex",
        flexDirection: "column",
        padding: 60,
        maxWidth: 800,
        alignItems: "center",
    },
    explanationText: {
        fontFamily: "Roboto_Light",
        fontSize: 30,
        textAlign: "center",
        lineHeight: 40,
    },
    explanationTextContainer: { marginBottom: 40 },
    block: {
        position: "absolute",
        height: "50%",
        width: "50%",
        resizeMode: "contain",
        left: "-20%",
        bottom: "-15%",
    },
    rightElementsContainer: {
        alignItems: "center",
        justifyContent: "center",
    },
});
