import { Audio } from "expo-av";
import React, { useEffect, useRef, useState } from "react";
import { View, StyleSheet, Dimensions, Platform } from "react-native";
import { LanguageService } from "../../../Service/LanguageService";
import { LanguageSelection } from "../../Modals/LanguageSelection";
import { UIButton } from "../../UI/UI";
import { MultiLanguage } from "../../../Service/Project";
import { Loading } from "../../UI/Loading";

const { height, width } = Dimensions.get("window");
let audioPlayer = new Audio.Sound();

export interface audioControllerProps {
    audio: MultiLanguage<string>;
    supportedTextLanguages: string[];
    currentTextLanguage: string;
    visible: boolean;
    index: string;
}

export const AudioController = (props: audioControllerProps) => {
    /** The state playing controls the state of the audio. If the app is muted,
     *  we set it to off by default. audioLanguage controls the audio language
     *  and gets it from the props. Finally, the volume gets whether the app is
     *  muted or not.
     */

    const [audioLanguage, setAudioLanguage] = useState<string>();

    const [playing, setPlaying] = useState(LanguageService.getAudioOnOff());
    const [volume, setVolume] = useState(LanguageService.getAudioOnOff());

    const [modalVisible, setModalVisible] = useState(false);

    const possibleTextLanguages = useRef(props.supportedTextLanguages);
    const possibleAudioLanguages = useRef(
        Object.keys(props.audio).filter((lan) =>
            LanguageService.checkSupportedLanguage(lan)
        )
    );

    useEffect(() => {
        // We subscribe to any change in the text or audio language
        const observer = {
            updateAudioLanguage,
            updateTextLanguage,
            updateVolume,
        };
        LanguageService.subscribe(observer);
        const requestedLanguage = LanguageService.getCurrentAudioLanguage();

        const currentAudioLan =
            !checkSameAudio(requestedLanguage) &&
            checkSupportAudio(requestedLanguage)
                ? requestedLanguage
                : audioLanguage ?? possibleAudioLanguages.current[0];

        if (currentAudioLan !== audioLanguage)
            setAudioLanguage(currentAudioLan);

        let serviceVolume = LanguageService.getAudioOnOff();
        if (serviceVolume !== volume) setVolume(serviceVolume);

        // When the component is unmounted, we unsubscribe and unload the audio
        return () => {
            LanguageService.unsubscribe(observer);
        };
    });

    useEffect(() => {
        // If the saved language in the service is available and not the current one,
        // we change it
        if (props.visible) {
            if (audioLanguage) changeAudio(audioLanguage);
        } else pauseSound();
    }, [props.visible, audioLanguage]);

    async function loadNewAudio(url: string) {
        const status = await audioPlayer.getStatusAsync();
        const newPlaying = playing;

        // 11-05-2021 audioPlayer.unloadAsync() is bugged on the browser. Thus, we must create a new sound.
        if (status.isLoaded) audioPlayer.pauseAsync();
        if (Platform.OS === "web") {
            audioPlayer = new Audio.Sound();
        } else if (status.isLoaded) {
            await audioPlayer.unloadAsync();
        }
        await audioPlayer.loadAsync({ uri: url });
        if (playing) audioPlayer.playAsync();
    }

    function checkSameAudio(newLan: string) {
        return newLan == audioLanguage;
    }

    function onVolumePressed() {
        LanguageService.setAudioOnOff(!volume);
        if (volume) pauseSound();
        setVolume(!volume);
    }

    async function onPlayPausePressed() {
        playing ? pauseSound() : playSound();
        return;
    }

    async function pauseSound() {
        const status = await audioPlayer.getStatusAsync();
        if (status.isLoaded) await audioPlayer.pauseAsync();
        if (playing) setPlaying(false);
        return;
    }

    async function playSound() {
        const status = await audioPlayer.getStatusAsync();
        if (status.isLoaded) await audioPlayer.playAsync();
        if (!playing) setPlaying(true);
        return;
    }

    function checkSupportAudio(newLan: string): boolean {
        return newLan in props.audio;
    }

    async function changeAudio(newLan: string) {
        await loadNewAudio(props.audio[newLan]);
        setAudioLanguage(newLan);
    }

    function updateAudioLanguage(newLan: string) {
        if (!checkSameAudio(newLan) && checkSupportAudio(newLan))
            changeAudio(newLan);
    }

    function updateTextLanguage(language: string) {}

    function updateVolume(onOff: boolean) {
        if (volume !== onOff) setVolume(onOff);
    }

    // This controls what image is shown in play and volume buttons
    // depending on the states
    const playImage = playing
        ? require("../../../../assets/icons/pause.png")
        : require("../../../../assets/icons/play.png");
    const volumeImage = volume
        ? require("../../../../assets/icons/volume.png")
        : require("../../../../assets/icons/mute.png");
    const volumeText = volume ? "ON" : "OFF";

    const textShortLan = LanguageService.getShortNameOfLanguage(
        props.currentTextLanguage
    );

    if (!audioLanguage) return <Loading />;

    const audioShortLan = LanguageService.getShortNameOfLanguage(audioLanguage);

    const audioTextShortLanConcat = audioShortLan
        .concat("/", textShortLan)
        .toUpperCase();

    return (
        <View style={styles.rowButtonContainer}>
            <LanguageSelection
                possibleAudioLanguages={possibleAudioLanguages.current}
                possibleTextLanguages={possibleTextLanguages.current}
                modalVisible={modalVisible}
                onClosed={() => setModalVisible(false)}
            />
            <View style={styles.buttonSize}>
                <UIButton
                    image={require("../../../../assets/icons/globe.png")}
                    text={audioTextShortLanConcat}
                    bold
                    onPress={() => setModalVisible(true)}
                />
            </View>
            <View style={styles.buttonSize}>
                <UIButton
                    image={volumeImage}
                    text={volumeText}
                    bold
                    onPress={onVolumePressed}
                />
            </View>
            <View style={styles.buttonSize}>
                <UIButton
                    image={playImage}
                    imagePercent={0.8}
                    bold
                    onPress={volume ? () => onPlayPausePressed() : undefined}
                    tintColor={volume ? undefined : "grey"}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    buttonSize: {
        height: height / 8,
        width: height / 8,
        marginLeft: 10,
        marginRight: 10,
    },
    rowButtonContainer: {
        flexDirection: "row",
    },
});
