import React, { useEffect, useRef, useState } from "react";
import {
    View,
    Text,
    Image,
    ScrollView,
    StyleSheet,
    ImageSourcePropType,
    Dimensions,
} from "react-native";
import { Loading } from "../../UI/Loading";
import {
    LanguageObserver,
    LanguageService,
} from "../../../Service/LanguageService";
import { MultiLanguage } from "../../../Service/Project";
import { ProjectFooter } from "../GeneralComponents/ProjectFooter";
import { AudioController } from "./AudioController";
import { TextAnimator } from "../../UI/TextAnimator";

const { width } = Dimensions.get("window");

export interface SimpleTextProps {
    image?: ImageSourcePropType;
    text: MultiLanguage<string>;
    height: number;
    audio?: MultiLanguage<string>;
    visible: boolean;
    index: string;
}

export const SimpleText = (props: SimpleTextProps) => {
    const [language, setLanguage] = useState<string>();
    const possibleTextLanguages = useRef(
        Object.getOwnPropertyNames(props.text).filter((lan) =>
            LanguageService.checkSupportedLanguage(lan)
        )
    );

    const image: ImageSourcePropType =
        props.image ?? require("../../../../assets/characters/Block.png");

    useEffect(() => {
        const observer: LanguageObserver = {
            updateAudioLanguage,
            updateTextLanguage,
        };
        LanguageService.subscribe(observer);
    });

    useEffect(() => {
        const requestedLanguage = LanguageService.getCurrentTextLanguage();
        if (
            !checkSameTextLan(requestedLanguage) &&
            checkTextLanSupport(requestedLanguage)
        )
            setLanguage(requestedLanguage);
        else if (!language) setLanguage(possibleTextLanguages.current[0]);
    });

    function updateAudioLanguage() {
        null;
    }

    function checkSameTextLan(newLan: string) {
        return newLan == language;
    }

    function checkTextLanSupport(newLan: string) {
        return newLan in props.text;
    }

    function updateTextLanguage(newLan: string) {
        if (!checkSameTextLan(newLan) && checkTextLanSupport(newLan))
            setLanguage(newLan);
    }

    if (!language) return <Loading />;
    return (
        <View style={{ width: width, height: props.height }}>
            <View style={styles.bodyContainer}>
                <View style={{ flex: 1 }}>
                    <Image source={image} style={styles.imageSize} />
                </View>
                <View style={styles.bubbleTextContainer}>
                    <View style={styles.bubble}>
                        <ScrollView
                            contentContainerStyle={styles.textScrollView}
                            persistentScrollbar
                        >
                            <Text style={styles.text}>
                                {props.text[language]}
                            </Text>
                        </ScrollView>
                    </View>
                </View>
            </View>
            <View
                style={{
                    flex: 20,
                }}
            >
                <ProjectFooter
                    homeButton
                    audioController={
                        props.audio ? (
                            <AudioController
                                audio={props.audio}
                                supportedTextLanguages={
                                    possibleTextLanguages.current
                                }
                                currentTextLanguage={language}
                                visible={props.visible}
                                index={props.index}
                            />
                        ) : undefined
                    }
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    bodyContainer: {
        flex: 80,
        display: "flex",
        flexDirection: "row",
        paddingLeft: 60,
        paddingRight: 60,
    },
    imageSize: {
        resizeMode: "contain",
        height: "100%",
        width: "100%",
    },
    bubbleTextContainer: {
        flex: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
    },
    bubble: {
        backgroundColor: "rgba(15,51,79,0.6)",
        height: "70%",
        width: "90%",
        borderRadius: 50,
        padding: 30,
        display: "flex",
        paddingTop: 50,
        paddingBottom: 50,
        justifyContent: "center",
    },
    textScrollView: {
        flexGrow: 1,
        justifyContent: "center",
        flexDirection: "column",
    },
    text: {
        fontFamily: "Roboto_Bold",
        color: "white",
        fontSize: 30,
        lineHeight: 40,
        flexShrink: 1,
    },
});
