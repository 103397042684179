import React, { useContext, useEffect, useState } from "react";
import {
    ScrollView,
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    Modal,
    TouchableWithoutFeedback,
    Image,
    Linking,
} from "react-native";
import { Divider } from "react-native-paper";
import {
    blue,
    bluePurpleish,
    darkGrey,
    highOpacityGrey,
    red,
    white,
} from "../../Colors";
import { VerticalPage } from "./VerticalPage";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import FontAwesome5Icon from "react-native-vector-icons/FontAwesome5";
import { User, Notification } from "../../Service/User";
import { AuthContext } from "../../provider/Auth";
import { NewInformation, News } from "../../Service/News";
import { Loading } from "../UI/Loading";
import { ColorButton } from "../UI/ColorButton";

export const ParentsHomePage = () => {
    const [modal, setModal] = useState(false);
    const [notifications, setNotifications] = useState<Notification[]>([]);

    const authContext = useContext(AuthContext);

    useEffect(() => {
        User.getNotifications(authContext!.userInfo!.userId)
            .then((res) => setNotifications(res))
            .catch(() => {});
    }, []);
    const onModalClose = () => {
        if (unreadNotifications > 0) {
            setNotifications(
                notifications.map((not) => {
                    return { ...not, read: true };
                })
            );
            User.postReadNotifications(authContext!.userInfo!.userId).catch(
                () => {}
            );
        }
        setModal(false);
    };
    const onBellPressed = () => setModal(true);

    const unreadNotifications =
        notifications.filter((not) => !not.read).length ?? 0;

    if (!authContext?.userInfo?.userId)
        return <Loading fullScreen background />;
    return (
        <VerticalPage>
            <ScrollView>
                <View style={styles.container}>
                    <Text style={styles.headerText}>Mur de les famílies</Text>
                    <TouchableOpacity
                        onPress={onBellPressed}
                        style={notStyles.smallBellContainer}
                    >
                        <View style={notStyles.smallBellSubC}>
                            {unreadNotifications > 0 ? (
                                <View style={notStyles.numberContainer}>
                                    <Text style={notStyles.number}>
                                        {unreadNotifications}
                                    </Text>
                                </View>
                            ) : undefined}
                            <FontAwesome5 name="bell" size={30} />
                        </View>
                    </TouchableOpacity>
                    <Divider style={styles.divider} />
                    <NewsBody userId={authContext!.userInfo!.userId} />
                </View>
            </ScrollView>
            <NotificationsModal
                modal={modal}
                onModalClose={onModalClose}
                notifications={notifications}
            />
        </VerticalPage>
    );
};

const NotificationsModal = (props: {
    modal: boolean;
    onModalClose: () => void;
    notifications: Notification[];
}) => {
    const { modal, onModalClose } = props;
    return (
        <Modal
            visible={modal}
            transparent
            animationType="none"
            onRequestClose={onModalClose}
        >
            <TouchableOpacity onPress={() => onModalClose()}>
                <View style={notStyles.modal}>
                    <TouchableWithoutFeedback>
                        <View style={notStyles.menuContainer}>
                            <Text style={notStyles.title}>Notificacions</Text>
                            <Divider style={notStyles.divider} />
                            {props.notifications.map((not, ind) => (
                                <NotificationElement
                                    notification={not}
                                    key={ind}
                                />
                            ))}

                            <NoMoreNotifications />
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </TouchableOpacity>
        </Modal>
    );
};

const NotificationElement = (props: { notification: Notification }) => {
    return (
        <>
            <View style={notStyles.notElement}>
                <FontAwesome5Icon
                    name={props.notification.icon}
                    size={20}
                    color={darkGrey}
                />
                <Text
                    style={[
                        notStyles.elementText,
                        props.notification.read
                            ? notStyles.light
                            : notStyles.bold,
                    ]}
                >
                    {props.notification.title}
                </Text>
            </View>
            <Divider style={notStyles.elementDivider} />
        </>
    );
};

const NoMoreNotifications = (props: { empty?: boolean }) => {
    return (
        <>
            <FontAwesome5Icon
                name="bell-slash"
                size={90}
                color={bluePurpleish}
                style={notStyles.bell}
            />
            <Text style={notStyles.noNotificationsText}>
                No tens cap {!props.empty ? "més " : ""}notificació. Estigues al
                cas, en rebràs a mesura que passi temps!
            </Text>
        </>
    );
};

const NewsBody = (props: { userId: string }) => {
    const [news, setNews] = useState<NewInformation[]>([]);
    const [fatalError, setFatalError] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        News.getUserNews(props.userId)
            .then((res) => setNews(res))
            .catch(() => setFatalError("Could not get the posts"))
            .finally(() => setLoading(false));
    }, []);

    if (loading) return <Loading />;
    if (fatalError) return <Text>{fatalError}</Text>;
    return (
        <>
            {news.map((newInfo, ind) => (
                <NewElement newInfo={newInfo} key={ind} />
            ))}
        </>
    );
};

const NewElement = (props: { newInfo: NewInformation }) => {
    const onButtonPress = () => {
        Linking.canOpenURL(props.newInfo.button!.url).then((supported) =>
            supported
                ? Linking.openURL(props.newInfo.button!.url)
                : console.log("Can't open this url")
        );
    };
    return (
        <View style={newStyles.newContainer}>
            <Image
                source={{ uri: props.newInfo.imageUrl }}
                style={newStyles.image}
            />
            <View style={newStyles.textContainer}>
                <Text style={newStyles.title}>{props.newInfo.title}</Text>
                <Text style={newStyles.body}>{`${props.newInfo.body.replace(
                    "<br>",
                    `\n \n`
                )}`}</Text>
                {props.newInfo.button ? (
                    <ColorButton
                        text={props.newInfo.button.text}
                        tColor={white}
                        bColor={props.newInfo.button.color ?? blue}
                        onPress={() => onButtonPress()}
                        style={newStyles.button}
                        centerText
                    />
                ) : undefined}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 40,
        marginLeft: "10%",
        marginRight: "10%",
        position: "relative",
    },
    backButton: {
        position: "absolute",
        left: 0,
        top: -5,
    },
    headerText: {
        fontFamily: "Roboto_Medium",
        fontSize: 35,
        textAlign: "center",
        marginBottom: 20,
    },
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        elevation: 4,
    },
    divider: {
        borderColor: blue,
        borderWidth: 2,
        width: "100%",
        marginBottom: 40,
    },
});

const notStyles = StyleSheet.create({
    smallBellContainer: {
        position: "absolute",
        right: 0,
        top: 8,
    },
    smallBellSubC: {
        position: "relative",
    },
    numberContainer: {
        position: "absolute",
        top: -8,
        right: -8,
        backgroundColor: red,
        zIndex: 10,
        borderRadius: 10,
        height: 20,
        width: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    number: {
        fontSize: 15,
        fontFamily: "Roboto_Bold",
        color: white,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        backgroundColor: highOpacityGrey,
    },
    menuContainer: {
        padding: 30,
        height: "100%",
        width: "40%",
        position: "absolute",
        right: 0,
        backgroundColor: white,
        alignItems: "center",
    },
    title: {
        textAlign: "center",
        fontFamily: "Roboto_Medium",
        fontSize: 30,
        marginBottom: 15,
    },
    divider: {
        height: 2,
        backgroundColor: blue,
        marginBottom: 20,
        width: "100%",
    },
    noNotificationsText: {
        marginTop: 20,
        fontFamily: "Roboto_Bold",
        fontSize: 20,
        color: bluePurpleish,
        textAlign: "center",
    },
    notElement: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 20,
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 20,
    },

    elementText: {
        fontFamily: "Roboto_Light",
        fontSize: 18,
        color: darkGrey,
        marginLeft: 25,
        flex: 1,
    },
    light: {
        fontFamily: "Roboto_Light",
    },
    bold: {
        fontFamily: "Roboto_Bold",
    },
    elementDivider: {
        width: "100%",
        height: 2,
        color: darkGrey,
        marginBottom: 20,
    },
    bell: {
        marginTop: 30,
    },
});

const newStyles = StyleSheet.create({
    newContainer: {
        display: "flex",
        flexDirection: "column",
        borderRadius: 40,
        backgroundColor: white,
        width: "80%",
    },
    image: {
        height: 400,
        width: "100%",
        resizeMode: "cover",
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40,
    },
    textContainer: {
        padding: 40,
        display: "flex",
        flexDirection: "column",
    },
    title: {
        fontFamily: "Roboto_Bold",
        fontSize: 30,
        marginBottom: 20,
        textAlign: "justify",
    },
    body: {
        fontFamily: "Roboto_Light",
        fontSize: 18,
        marginBottom: 30,
        textAlign: "justify",
    },
    button: {
        width: "50%",
        alignSelf: "center",
    },
});
