import { ApiService } from "./ApiService";
import { ParticipantsTeamSummary } from "./ParticipantsTeam";
import { firebaseFunctions } from "../firebase";

export class UserInformation {
    static multiplePlayers = false;
    private static childName: string;

    static async getChildName(): Promise<string> {
        if (!this.childName) {
            this.childName = await ApiService.getCurrentChildName();
            return this.childName;
        }
        return this.childName;
    }
}
export interface KidBasicInfo {
    name: string;
    surname: string;
    birthDate: number;
    gender: "male" | "female";
}

export interface KidInfo extends KidBasicInfo {
    kidId: string;
    avatar?: string;
    specialProjectDistributionsIds?: string[];
    teams?: {
        onGoing?: ParticipantsTeamSummary[];
        finished?: ParticipantsTeamSummary[];
    };
    stars?: number;
    defaultTextLanguage?: string;
    defaultAudioLanguage?: string;
    permissions: {
        commercialInformation: boolean;
        imageRights: boolean;
    };
}
/* 
export interface ProjectParticipation {
    projectId: string;
    groupId: string;
    subscriptionId: string;
    participantsTeamId: string;
    active: boolean;
} */

export interface UserInformation {
    children: { [key: string]: KidInfo };
    parentsIds: string[];
    role?: "kid" | "instructor" | "admin";
    userId: string;
    parentsInformation?: ParentInformation[];
    authUid?: string[];
    province: string;
}

export interface ParentInformation {
    parentId: string;
    name?: string;
    surname?: string;
    city?: string;
    cp?: number;
    email: string;
    childrenIds?: string[];
    preferredLanguage?: string;
    phoneNumber?: string;
}

export interface Notification {
    title: string;
    icon: string;
    read?: boolean;
}

export class User {
    constructor() {}
    static async createUserFromAuthUid(user: UserInformation) {
        return await firebaseFunctions.httpsCallable("users-postNewUser")({
            user: user,
        });
    }
    static async createNewParent(parent: ParentInformation) {
        return await firebaseFunctions.httpsCallable("users-postParent")({
            parent: parent,
        });
    }

    static async getNotifications(userId: string): Promise<Notification[]> {
        const response = await firebaseFunctions.httpsCallable(
            "users-getNotifications"
        )({
            userId: userId,
        });
        return response.data as Notification[];
    }

    static async postReadNotifications(userId: string) {
        return await firebaseFunctions.httpsCallable(
            "users-postReadNotifications"
        )({
            userId: userId,
        });
    }
}
