import React, { useContext, useEffect, useState } from "react";
import {
    View,
    Image,
    Text,
    StyleSheet,
    StyleProp,
    ViewStyle,
    TouchableOpacity,
    useWindowDimensions,
} from "react-native";
import { ChildrenContext } from "../../provider/Children";
import { shadow, white } from "../../Colors";
import { KidInfo } from "../../Service/User";
import { FullScreenBackground } from "../UI/UI";
import { Loading } from "../UI/Loading";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import { useNavigation } from "@react-navigation/core";
import firebase from "firebase/app";
import { ProjectContext } from "../../provider/Project";

export const ChildrenSelection = () => {
    const childrenContext = useContext(ChildrenContext);
    const [children, setChildren] = useState<KidInfo[]>();
    const [fatalError, setFatalError] = useState("");
    const [loading, setLoading] = useState(true);

    const navigation = useNavigation();
    const projectContext = useContext(ProjectContext);

    const onExitPressed = () => {
        firebase.auth().signOut();
        setLoading(true);
    };

    const onParentsPressed = () => {
        navigation.navigate("ParentsZone");
    };

    const onKidPressed = async (id: string) => {
        childrenContext?.setCurrentKidId(id);
        navigation.navigate("KidZone");
    };

    useEffect(() => {
        getAndSetChildren();
        setLoading(false);
    }, []);

    function getAndSetChildren() {
        childrenContext?.children
            ? setChildren(childrenContext.children)
            : setFatalError(
                  "We cannot get the children. Please try again later"
              );
    }

    if (loading) return <Loading fullScreen background />;

    return (
        <FullScreenBackground>
            {fatalError ? (
                <Text style={styles.fatalError}>{fatalError}</Text>
            ) : (
                <View style={[styles.container]}>
                    <Text style={styles.title}>QUI ETS?</Text>
                    <View style={styles.rowKids}>
                        {children?.map((kid, i) => {
                            return (
                                <KidContainer
                                    kid={kid}
                                    style={{
                                        marginRight:
                                            i < children.length - 1
                                                ? 20
                                                : undefined,
                                    }}
                                    onKidPressed={onKidPressed}
                                    key={i}
                                />
                            );
                        })}
                    </View>
                    <ParentsButton onParentsPressed={onParentsPressed} />
                    <ExitButton onExitPressed={onExitPressed} />
                </View>
            )}
        </FullScreenBackground>
    );
};

const KidContainer = (props: {
    style?: StyleProp<ViewStyle>;
    kid: KidInfo;
    onKidPressed: (id: string) => void;
}) => {
    return (
        <View style={[props.style]}>
            <TouchableOpacity
                onPress={() => props.onKidPressed(props.kid.kidId)}
            >
                <View style={styles.kidContainer}>
                    <Image
                        source={
                            props.kid.avatar
                                ? { uri: props.kid.avatar }
                                : props.kid.gender === "male"
                                ? require("../../../assets/icons/male_kid.png")
                                : require("../../../assets/icons/female_kid.png")
                        }
                        style={styles.kidImage}
                    />
                </View>
                <Text style={styles.kidName}>{props.kid.name}</Text>
            </TouchableOpacity>
        </View>
    );
};

const ParentsButton = (props: { onParentsPressed: () => void }) => {
    return (
        <View style={styles.parentsButton}>
            <TouchableOpacity onPress={() => props.onParentsPressed()}>
                <FontAwesome name="graduation-cap" size={100} color={white} />
                <Text style={styles.buttonText}>ZONA PARES</Text>
            </TouchableOpacity>
        </View>
    );
};

const ExitButton = (props: { onExitPressed: () => void }) => {
    return (
        <View style={styles.exitButton}>
            <TouchableOpacity onPress={() => props.onExitPressed()}>
                <FontAwesome name="sign-out" size={100} color={white} />
                <Text style={styles.buttonText}>TANCAR</Text>
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        height: "100%",
        width: "100%",
        padding: "5%",
        display: "flex",
        flexDirection: "column",
    },
    rowKids: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        fontSize: 70,
        textAlign: "center",
        fontFamily: "Roboto_Bold",
        color: "white",
        textShadowColor: "grey",
        textShadowOffset: { width: 0, height: 2 },
        textShadowRadius: 10,
    },
    kidContainer: {
        backgroundColor: white,
        borderRadius: 100000,
        padding: 30,
        marginBottom: 20,
        marginLeft: 30,
        marginRight: 30,
        ...shadow,
    },
    kidImage: {
        width: 250,
        height: 250,
        resizeMode: "contain",
        marginBottom: 10,
    },
    kidName: {
        textAlign: "center",
        fontFamily: "Roboto_Medium",
        fontSize: 30,
        color: white,
    },
    parentsButton: {
        position: "absolute",
        right: 30,
        top: 30,
    },
    buttonText: {
        fontFamily: "Roboto_Bold",
        fontSize: 20,
        color: white,
        textAlign: "center",
    },
    exitButton: {
        position: "absolute",
        right: 30,
        bottom: 30,
    },
    fatalError: {
        textAlign: "center",
        fontFamily: "Roboto",
        fontSize: 20,
    },
});
