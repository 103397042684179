import React from "react";

import { View, StyleSheet, Image, Text, Dimensions } from "react-native";
import { PublicProjectInformation } from "../../../Service/Project";

/** The Project Preview Interface
 * @param numberOfNotifications NUMBER The outstanding notifications for this project and child
 * @param imageUrl STRING The url from which to obtain the image
 * @param title STRING The title of the project
 */
interface ProjectPreview {
    project: PublicProjectInformation;
}

/** Returns a View with a Project Preview. Generally used with the ProjectScrollView
 */
export const ProjectPreview = (props: ProjectPreview) => {
    const projectHeight = Dimensions.get("window").height * 0.5;
    const projectWidth = Dimensions.get("window").width * 0.51;

    return (
        <View style={[previewStyles.projectPreviewContainer]}>
            <View
                style={[
                    previewStyles.imageContainer,
                    {
                        width: projectWidth,
                        height: projectHeight,
                        maxWidth: 600,
                        backgroundColor: "white",
                        borderRadius: projectWidth / 5,
                    },
                ]}
            >
                <Image
                    source={{
                        uri: props.project.urlImages?.backgroundImage,
                    }}
                    style={{
                        flex: 1,
                        borderRadius: projectWidth / 5,
                    }}
                ></Image>
            </View>
            <Text
                style={[
                    previewStyles.projectPreviewTitle,
                    { width: projectWidth, maxWidth: 600 },
                ]}
            >
                {props.project.projectName["catalan"]}
            </Text>
        </View>
    );
};

/** The Stylesheet used to style the Project Preview */
const previewStyles = StyleSheet.create({
    projectPreviewContainer: {
        display: "flex",
        flexDirection: "column",
    },
    imageContainer: {
        shadowColor: "black",
        shadowOffset: { width: 0, height: 10 },
        shadowOpacity: 0.5,
        shadowRadius: 15,
    },
    projectPreviewImage: {},
    projectPreviewTitle: {
        marginTop: 20,
        fontSize: 30,
        textAlign: "center",
        color: "white",
        textShadowColor: "black",
        textShadowOffset: { width: 3, height: 3 },
        textShadowRadius: 10,
        fontFamily: "Roboto_Bold",
    },
});
