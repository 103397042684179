import React, { useContext, useEffect, useState } from "react";
import {
    View,
    Text,
    StyleSheet,
    Image,
    ScrollView,
    Modal,
    Switch,
    TouchableOpacity,
} from "react-native";
import { ActivityIndicator, Chip, Divider, Snackbar } from "react-native-paper";
import {
    green,
    blue,
    red,
    yellow,
    white,
    mediumGrey,
    darkBlue,
    highOpacityGrey,
} from "../../Colors";
import { VerticalPage } from "./VerticalPage";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import { ColorButton } from "../UI/ColorButton";
import { Group, GroupPublicData, WeekDay } from "../../Service/Group";
import { capFirstLetter } from "../../utils/Capitalize";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/core";
import { Product, Store } from "../../Service/Store";
import { StoreContext } from "../../provider/Store";
import { Loading } from "../UI/Loading";
import { ParticipantsTeam } from "../../Service/ParticipantsTeam";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../provider/Auth";
import { KidInfo } from "../../Service/User";
import { ProjectContext } from "../../provider/Project";

type ProductDetailsRouteList = {
    Product: {
        projectId: string;
    };
};

export const ProductDetailsPage = () => {
    const [product, setProduct] = useState<Product>();
    const [groups, setGroups] = useState<GroupPublicData[]>();
    const [loading, setLoading] = useState(true);
    const [fatalError, setFatalError] = useState("");

    const route = useRoute<RouteProp<ProductDetailsRouteList, "Product">>();
    const storeContext = useContext(StoreContext);
    const navigation = useNavigation();

    useEffect(() => {
        loadProductDetails()
            .catch(() => setFatalError("Cannot get the product details"))
            .finally(() => setLoading(false));
    }, []);

    async function loadProductDetails() {
        const projectId = route.params.projectId;
        const product = storeContext?.storeMainPage?.products.find(
            (product) => product.projectId === projectId
        );
        const groups = await Store.getGroupsFromProjectId(projectId);
        setProduct(product);
        setGroups(groups);
    }

    const goBack = () => navigation.goBack();
    if (loading) return <Loading fullScreen />;
    if (fatalError) return <Text>{fatalError}</Text>;
    return (
        <VerticalPage>
            <ScrollView>
                <View style={styles.container}>
                    <TouchableOpacity
                        onPress={() => goBack()}
                        style={styles.backButton}
                    >
                        <MaterialIcons name="arrow-back" size={50} />
                    </TouchableOpacity>
                    <Text style={styles.headerText}>{product!.title}</Text>
                    <Divider
                        style={{
                            borderColor: blue,
                            borderWidth: 2,
                            width: "100%",
                        }}
                    />
                    <ProductElement product={product!} groups={groups!} />
                </View>
            </ScrollView>
        </VerticalPage>
    );
};

interface ProductElementProps {
    product: Product;
    groups: GroupPublicData[];
}

const ProductElement = (props: ProductElementProps) => {
    function getChipColors(ind: number) {
        switch (ind % 4) {
            case 0:
                return green;
            case 1:
                return blue;
            case 2:
                return red;
            case 3:
                return yellow;
        }
    }
    return (
        <View style={[styles.shadow, projStyles.container]}>
            <View style={projStyles.imageContainer}>
                <View style={projStyles.chipsContainer}>
                    {props.product.chips?.map((chip, ind) => (
                        <Chip
                            key={ind}
                            style={[
                                projStyles.chip,
                                { backgroundColor: getChipColors(ind) },
                            ]}
                            textStyle={projStyles.chipText}
                        >
                            {chip}
                        </Chip>
                    ))}
                </View>

                <Image
                    style={projStyles.image}
                    source={{ uri: props.product.imageUrl }}
                />
            </View>
            <View style={projStyles.cardBody}>
                <Text style={projStyles.title}>{props.product.title}</Text>
                <Description description={props.product.description} />
                <Text style={projStyles.title}>Inscripció</Text>
                {props.groups.map((group, ind) => (
                    <GroupElement
                        key={ind}
                        group={group}
                        product={props.product}
                    />
                ))}
            </View>
        </View>
    );
};

const Description = (props: { description: string }) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <View>
            <View
                style={{
                    maxHeight: !expanded ? 300 : undefined,
                }}
            >
                <Text
                    style={[
                        projStyles.description,
                        { maxHeight: !expanded ? 300 : undefined },
                    ]}
                >
                    {props.description}
                </Text>
            </View>
            <MaterialIcons
                name={expanded ? "keyboard-arrow-up" : "keyboard-arrow-down"}
                onPress={() => setExpanded(!expanded)}
                style={projStyles.arrow}
                size={30}
                color={mediumGrey}
            />
        </View>
    );
};

const GroupElement = (props: { group: GroupPublicData; product: Product }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const onInscriptionClick = () => setModalOpen(true);
    const onModalClose = () => setModalOpen(false);
    return (
        <View>
            <Divider style={{ borderColor: darkBlue, borderWidth: 1 }} />
            <View style={projStyles.groupElementContainer}>
                <FontAwesome5
                    name="robot"
                    size={30}
                    style={projStyles.robotIcon}
                    color={darkBlue}
                />
                <View style={projStyles.groupFlexContainer}>
                    <Text style={projStyles.label}>Nom del grup</Text>
                    <Text style={projStyles.details}>{props.group.name}</Text>
                    <Text style={projStyles.label}>Idioma</Text>
                    <Text style={projStyles.details}>
                        {capFirstLetter(props.group.language)}
                    </Text>
                    <Text style={projStyles.label}>Horari</Text>
                    {props.group.schedule.map((val, ind) => (
                        <Text key={ind} style={projStyles.details}>
                            {Group.scheduleToText([val])}
                        </Text>
                    ))}
                </View>
                <View style={projStyles.groupFlexContainer}>
                    <Text style={projStyles.label}>Projecte</Text>
                    <Text style={projStyles.details}>
                        {props.product.projectName}
                    </Text>
                    <ColorButton
                        bColor={blue}
                        tColor={white}
                        onPress={() => onInscriptionClick()}
                        text="Inscriu-te!"
                        centerText
                        style={projStyles.button}
                    />
                </View>
            </View>
            <InscriptionModal
                open={modalOpen}
                product={props.product}
                onModalClose={onModalClose}
                group={props.group}
            />
        </View>
    );
};

const InscriptionModal = (props: {
    group: GroupPublicData;
    product: Product;
    open: boolean;
    onModalClose: () => void;
}) => {
    const [participants, setParticipants] = useState<string[]>([]);
    const [possibleParticipants, setPossibleParticipants] = useState<KidInfo[]>(
        []
    );
    const [snackbar, setSnackbar] = useState({
        active: false,
        message: "",
        color: red,
    });
    const [paying, setPaying] = useState(false);

    const navigation = useNavigation();
    const authContext = useContext(AuthContext);
    const projectContext = useContext(ProjectContext);

    useEffect(() => {
        const children = Object.values(authContext!.userInfo!.children);

        setPossibleParticipants(children);
    }, [authContext]);

    const goToPayment = async () => {
        if (participants.length === 0) {
            setSnackbar({
                active: true,
                message: "Has de seleccionar com a mínim un participant",
                color: red,
            });
            return;
        }
        setPaying(true);

        Promise.all(
            participants.map(
                async (part) =>
                    await ParticipantsTeam.postNewParticipantsTeam({
                        groupId: props.group.groupId,
                        participantsIds: [part],
                        teamId: uuidv4(),
                        projectId: props.product.projectId,
                    })
            )
        )
            .then(() => {
                setSnackbar({
                    active: true,
                    message: "Felicitats! Has adquirit el projecte!",
                    color: green,
                });
                setTimeout(() => {
                    props.onModalClose();
                    projectContext?.resetProjects();
                    navigation.navigate("KidZone");
                }, 3000);
            })
            .catch(() =>
                setSnackbar({
                    active: true,
                    message:
                        "Hi ha hagut algun problema i no es pot acabar la transacció",
                    color: red,
                })
            )
            .finally(() => setPaying(false));
    };
    const onSwitchChange = (id: string) => {
        let index = participants.indexOf(id);
        let copiedArray = [...participants];
        index === -1 ? copiedArray.push(id) : copiedArray.splice(index, 1);
        setParticipants(copiedArray);
    };

    let price = props.product.price.toFixed(2).toString();
    let totalPrice = (props.product.price * participants.length)
        .toFixed(2)
        .toString();
    return (
        <Modal
            visible={props.open}
            onRequestClose={props.onModalClose}
            transparent
            animationType="fade"
        >
            <View style={modalStyles.modal}>
                <View style={modalStyles.inscriptionContainer}>
                    <TouchableOpacity
                        style={modalStyles.closeButton}
                        onPress={() => props.onModalClose()}
                    >
                        <View>
                            <MaterialIcons name="close" size={50} />
                        </View>
                    </TouchableOpacity>
                    <Text style={modalStyles.title}>
                        Confirmació de la inscripció
                    </Text>
                    <Text style={modalStyles.sectionTitle}>
                        Basic Information
                    </Text>
                    <Divider />
                    <View style={modalStyles.detailsContainer}>
                        <View style={modalStyles.detailSubC}>
                            <Text style={modalStyles.label}>Nom del grup</Text>
                            <Text style={modalStyles.details}>
                                {props.group.name}
                            </Text>
                            <Text style={modalStyles.label}>Idioma</Text>
                            <Text style={modalStyles.details}>
                                {props.group.language}
                            </Text>
                        </View>
                        <View style={modalStyles.detailSubC}>
                            <Text style={modalStyles.label}>Projecte</Text>
                            <Text style={modalStyles.details}>
                                {props.product.projectName}
                            </Text>
                            <Text style={modalStyles.label}>Horari</Text>
                            <Text style={modalStyles.details}>
                                {Group.scheduleToText(props.group.schedule)}
                            </Text>
                        </View>
                    </View>
                    <Text style={modalStyles.sectionTitle}>Participants</Text>
                    <Divider />
                    <View style={modalStyles.participantsDetailsContainer}>
                        {possibleParticipants.map((part, ind) => {
                            return (
                                <View key={ind} style={modalStyles.participant}>
                                    <Switch
                                        value={participants.includes(
                                            part.kidId
                                        )}
                                        onValueChange={() =>
                                            onSwitchChange(part.kidId)
                                        }
                                        trackColor={{
                                            true: blue,
                                            false: mediumGrey,
                                        }}
                                    />
                                    <Text style={modalStyles.participantText}>
                                        {part.name}
                                    </Text>
                                </View>
                            );
                        })}
                    </View>

                    <Text style={modalStyles.sectionTitle}>Preu</Text>
                    <Divider />
                    <View style={modalStyles.priceDetailsContainer}>
                        <Text style={modalStyles.label}>
                            Mensualitat per nen
                        </Text>
                        <Text style={modalStyles.details}>{price}€</Text>
                        <Text style={modalStyles.label}>Mensualitat total</Text>
                        <Text style={modalStyles.details}>{totalPrice}€</Text>
                    </View>

                    <ColorButton
                        bColor={blue}
                        tColor={white}
                        onPress={() => goToPayment()}
                        text="Procedir al pagament"
                        centerText
                        style={projStyles.button}
                    />
                    {paying ? <ActivityIndicator color={blue} /> : undefined}
                </View>
            </View>
            <Snackbar
                visible={snackbar.active}
                onDismiss={() =>
                    setSnackbar({
                        active: false,
                        message: "",
                        color: highOpacityGrey,
                    })
                }
                duration={3000}
                style={{
                    backgroundColor: snackbar.color,
                }}
            >
                {snackbar.message}
            </Snackbar>
        </Modal>
    );
};
const projStyles = StyleSheet.create({
    container: {
        backgroundColor: white,
        width: "100%",
        marginBottom: 30,
        marginTop: 30,
        borderRadius: 40,
    },
    imageContainer: {
        position: "relative",
        width: "100%",
        height: 350,
    },
    image: {
        height: "100%",
        width: "100%",
        resizeMode: "cover",
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40,
    },
    cardBody: {
        padding: 30,
    },
    title: {
        fontFamily: "Roboto_Bold",
        fontSize: 25,
        marginBottom: 10,
    },
    description: {
        fontFamily: "Roboto_Light",
        fontSize: 18,
        lineHeight: 24,
        textAlign: "justify",
        overflow: "hidden",
    },
    details: {
        fontFamily: "Roboto_Light",
        fontSize: 16,
        paddingLeft: 20,
        marginBottom: 5,
        flexWrap: "wrap",
    },
    label: {
        fontFamily: "Roboto_Medium",
        textAlign: "left",
        fontSize: 16,
        marginBottom: 5,
        color: blue,
        marginTop: 5,
    },
    price: {
        fontFamily: "Roboto",
        fontSize: 25,
    },
    chipsContainer: {
        zIndex: 10,
        position: "absolute",
        top: 0,
        padding: 30,
    },
    chip: {
        color: white,
        marginBottom: 10,
    },
    chipText: {
        color: white,
        fontFamily: "Roboto_Bold",
        fontSize: 18,
    },
    arrow: {
        textAlign: "center",
        marginTop: 10,
    },
    groupElementContainer: {
        display: "flex",
        flexDirection: "row",
        marginTop: 30,
        marginBottom: 30,
    },
    groupFlexContainer: {
        flex: 1,
        paddingLeft: 30,
    },
    button: {
        marginTop: "auto",
        alignSelf: "flex-end",
    },
    robotIcon: {
        alignSelf: "center",
        paddingLeft: 10,
    },
});

const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 40,

        marginLeft: "10%",
        marginRight: "10%",
        position: "relative",
    },
    backButton: {
        position: "absolute",
        left: 0,
        top: -5,
    },
    headerText: {
        fontFamily: "Roboto_Medium",
        fontSize: 35,
        textAlign: "center",
        marginBottom: 20,
    },
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,

        elevation: 4,
    },
});

const modalStyles = StyleSheet.create({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        backgroundColor: highOpacityGrey,
    },
    inscriptionContainer: {
        width: "80%",
        maxWidth: 800,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: 20,
        padding: 40,
        position: "relative",
    },
    closeButton: {
        position: "absolute",
        top: 30,
        right: 20,
    },
    title: {
        fontFamily: "Roboto_Bold",
        fontSize: 30,
        marginBottom: 30,
        textAlign: "center",
    },
    details: {
        fontFamily: "Roboto_Light",
        fontSize: 16,
        paddingLeft: 20,
        marginBottom: 5,
        flexWrap: "wrap",
    },
    label: {
        fontFamily: "Roboto_Medium",
        textAlign: "left",
        fontSize: 16,
        marginBottom: 5,
        marginTop: 5,
    },
    detailsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: 20,
    },
    sectionTitle: {
        fontFamily: "Roboto_Medium",
        fontSize: 20,
        marginBottom: 10,
        color: blue,
    },
    priceDetailsContainer: {
        margin: 20,
    },
    participantsDetailsContainer: {
        margin: 20,
    },
    participant: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 10,
    },
    participantText: {
        marginLeft: 10,
        fontFamily: "Roboto_Light",
        fontSize: 16,
    },
    detailSubC: {
        marginRight: 20,
        maxWidth: "50%",
    },
});
