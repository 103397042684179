import { useNavigation } from "@react-navigation/core";
import React from "react";
import { Platform, View, StyleSheet } from "react-native";
import { FAB } from "react-native-paper";
import { purpleConstrast, white } from "../../Colors";

export const VerticalPage = (props: { children: any }) => {
    if (Platform.OS === "web")
        return (
            <View
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                }}
            >
                <View
                    style={{
                        maxWidth: 1000,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {props.children}
                </View>
                <Fab />
            </View>
        );

    return (
        <View style={{ flex: 1, overflow: "visible" }}>
            {props.children}
            <Fab />
        </View>
    );
};

const Fab = () => {
    const navigation = useNavigation();
    return (
        <FAB
            style={styles.fab}
            icon="robot"
            onPress={() => navigation.navigate("KidZone")}
            color={white}
            label={"Torna a la zona de nens"}
        />
    );
};

const styles = StyleSheet.create({
    fab: {
        position: "absolute",
        margin: 16,
        right: 0,
        bottom: 0,
        backgroundColor: purpleConstrast,
    },
});
