export class Group {
    constructor() {}

    static scheduleToText(schedule: ClassDay[] /* , t: TFunction<string> */) {
        return schedule
            .map(
                (day) =>
                    `${
                        day.weekDay!.charAt(0).toUpperCase() +
                        day.weekDay!.slice(1)
                    } from ${day.startHour} to ${day.finishHour}`
            )
            .join(" and ");
    }
}

export interface ClassDay {
    weekDay?: WeekDay;
    startHour?: string;
    finishHour?: string;
}

export type WeekDay =
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday"
    | "sunday";

export interface GroupPublicData {
    groupId: string;
    language: string;
    schedule: {
        startHour: string;
        finishHour: string;
        weekDay: WeekDay;
    }[];
    startDate: number;
    finishDate: number;
    name: string;
    projectId: string;
}
