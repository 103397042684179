import React, { useContext, useEffect, useState } from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import { MainPageHeader } from "./Header/MainPageHeader";
import { ProjectScrollView } from "./ProjectScrollView";
import { PublicProjectInformation } from "../../../Service/Project";
import { FullScreenBackground } from "../../UI/UI";
import { Loading } from "../../UI/Loading";
import { ProjectContext } from "../../../provider/Project";
import { FAB, Snackbar } from "react-native-paper";
import { purpleConstrast, red, white } from "../../../Colors";
import { useNavigation } from "@react-navigation/core";

const headerFlex = 18;
const bodyFlex = 82;

const { height } = Dimensions.get("window");

/** Returns the Home Screen, composed by a Header with the name of the kid and the buttons
 *  to access the parents zone and the videocall and the Content with the list of projects */

export const HomeScreen = () => {
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState<PublicProjectInformation[]>([]);
    const [snackbar, setSnackbar] = useState({ active: false, message: "" });
    const navigation = useNavigation();

    const projectContext = useContext(ProjectContext);

    useEffect(() => {
        if (!projectContext) return;

        setProjects(projectContext.projects!);
        setLoading(false);
    }, [projectContext]);

    if (loading) return <Loading background fullScreen />;

    return (
        <View style={styles.container}>
            <FullScreenBackground>
                <View style={styles.headerContainer}>
                    <MainPageHeader
                        headerHeight={
                            (height * headerFlex) / (headerFlex + bodyFlex)
                        }
                        onSnackbarChange={(snackbar) => setSnackbar(snackbar)}
                    />
                </View>
                <View style={styles.bodyContainer}>
                    <ProjectScrollView projects={projects}></ProjectScrollView>
                </View>
                <Snackbar
                    visible={snackbar.active}
                    style={{ backgroundColor: red }}
                    onDismiss={() =>
                        setSnackbar({ ...snackbar, active: false })
                    }
                >
                    {snackbar.message}
                </Snackbar>
                <FAB
                    icon="robot"
                    style={styles.fab}
                    label="Canviar de persona"
                    onPress={() => navigation.navigate("ChildrenSelection")}
                />
            </FullScreenBackground>
        </View>
    );
};

/** A StyleSheet used to style the Home Screen */
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    headerContainer: { flex: headerFlex },
    bodyContainer: { flex: bodyFlex, justifyContent: "center" },
    exitButton: {
        position: "absolute",
        right: 30,
        bottom: 30,
    },
    buttonText: {
        fontFamily: "Roboto_Bold",
        fontSize: 20,
        color: white,
        textAlign: "center",
    },
    fab: {
        position: "absolute",
        margin: 16,
        right: 0,
        bottom: 0,
        backgroundColor: purpleConstrast,
    },
});
