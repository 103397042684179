import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { HomeScreen } from "./components/KidZone/HomeScreen/HomeScreen";
import { PlayerChoice } from "./components/KidZone/PlayerChoice";

import { LevelSelection } from "./components/KidZone/LevelSelection";
import * as Font from "expo-font";
import { LevelPage } from "./components/KidZone/LevelPage";
import { FeedbackPage } from "./components/KidZone/FeedbackPage";
import { Welcome } from "./components/Introduction/Welcome";
import { Tour } from "./components/Introduction/Tour";
import { RegisterChildren } from "./components/Introduction/RegisterChildren";
import { SignIn } from "./components/Introduction/SignIn";
import { AuthContext, AuthProvider } from "./provider/Auth";
import { useContext } from "react";
import { AccountFound } from "./components/Introduction/AccountFound";
import { ParentsZone } from "./components/ParentsZone/ParentsZone";
import { ChildrenContext, ChildrenProvider } from "./provider/Children";
import { ChildrenSelection } from "./components/Introduction/ChildrenSelection";
import { LogBox } from "react-native";
import { ProjectContext, ProjectProvider } from "./provider/Project";
import { Loading } from "./components/UI/Loading";

LogBox.ignoreLogs(["Setting a timer"]);

export type BodyParamList = {
    Introduction: undefined;
    Home: undefined;
    PlayerChoice: undefined;
    AvatarSelection: undefined;
    LevelSelection: undefined;
    LevelPage: undefined;
    FeedbackPage: undefined;
    Test: undefined;
    ParentsZone: undefined;
};

export type AuthParamList = {
    Introduction: undefined;
    Welcome: undefined;
    Tour: undefined;
    SignIn: undefined;
    RegisterChildren: undefined;
    AccountFound: undefined;
};

type AppParamList = {
    Auth: undefined;
    Body: undefined;
};

export default function App() {
    const [loaded] = Font.useFonts({
        Roboto: require("../assets/roboto/Roboto-Regular.ttf"),
        Roboto_Bold: require("../assets/roboto/Roboto-Bold.ttf"),
        Roboto_Light: require("../assets/roboto/Roboto-Light.ttf"),
        Roboto_Medium: require("../assets/roboto/Roboto-Medium.ttf"),
    });
    /*     ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE);
     */ if (!loaded) {
        return null;
    }

    return (
        <AuthProvider>
            <ChildrenProvider>
                <ProjectProvider>
                    <Navigation />
                </ProjectProvider>
            </ChildrenProvider>
        </AuthProvider>
    );
}

const Navigation = () => {
    const Stack = createStackNavigator<AppParamList>();
    const [access, setAccess] = useState(false);
    const authContext = useContext(AuthContext);

    console.log("Navigation");

    useEffect(() => {
        if (authContext?.userInfo?.userId && access === false) {
            setAccess(true);
        } else if (!authContext?.userInfo?.userId && access === true) {
            setAccess(false);
        }
    }, [authContext]);

    return (
        <View style={styles.container}>
            <NavigationContainer>
                <Stack.Navigator screenOptions={{ headerShown: false }}>
                    {access ? (
                        <Stack.Screen name="Body" component={BodyNavigation} />
                    ) : (
                        <Stack.Screen name="Auth" component={AuthNavigation} />
                    )}
                </Stack.Navigator>
            </NavigationContainer>
        </View>
    );
};
const AuthNavigation = () => {
    const Stack = createStackNavigator<AuthParamList>();

    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Welcome" component={Welcome} />
            <Stack.Screen name="Tour" component={Tour} />
            <Stack.Screen name="AccountFound" component={AccountFound} />
            <Stack.Screen name="SignIn" component={SignIn} />
            <Stack.Screen
                name="RegisterChildren"
                component={RegisterChildren}
            />
        </Stack.Navigator>
    );
};

const BodyNavigation = () => {
    const Stack = createStackNavigator();

    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen
                name="ChildrenSelection"
                component={ChildrenSelection}
            />
            <Stack.Screen name="ParentsZone" component={ParentsZone} />

            <Stack.Screen name="KidZone" component={KidZone} />
        </Stack.Navigator>
    );
};

const KidZone = () => {
    const Stack = createStackNavigator();
    const projectContext = useContext(ProjectContext);
    const childrenContext = useContext(ChildrenContext);
    const [loading, setLoading] = useState(true);
    const navigation = useNavigation();

    if (!childrenContext?.currentKidId)
        navigation.navigate("ChildrenSelection");

    useEffect(() => {
        projectContext?.projects ? setLoading(false) : setLoading(true);
    }, [projectContext]);

    if (loading) return <Loading fullScreen background />;
    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Home" component={HomeScreen} />
            <Stack.Screen name="PlayerChoice" component={PlayerChoice} />
            <Stack.Screen name="LevelSelection" component={LevelSelection} />
            <Stack.Screen name="LevelPage" component={LevelPage} />
            <Stack.Screen name="FeedbackPage" component={FeedbackPage} />
        </Stack.Navigator>
    );
};
/** The view is mandatory to have flex 1 */
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});
