import { LevelProgress, ParticipantsTeamSummary } from "./ParticipantsTeam";
import { MultiLanguage } from "./Project";
import { firebaseFunctions } from "../firebase";

export type PublicLevel = {
    id: string;
    number: number;
    levelProgress?: LevelProgress;
    unlocked?: boolean;
    steps?: PublicLevelStep[] | null;
};

export type Feedback = {
    instructorName?: string;
    instructorImage?: string;
    instructorId: string;
    stars: number;
    content: FeedbackText | FeedbackVideo;
};

export interface FeedbackText {
    type: "text";
    text: string;
}

export interface FeedbackVideo {
    type: "video";
    video: string;
}

export type PublicLevelStep =
    | {
          id: string;
          type: "SimpleText";
          data: PublicSimpleTextData;
      }
    | {
          id: string;
          type: "SimpleVideo";
          data: PublicSimpleVideoData;
      }
    | {
          id: string;
          type: "SimpleInstructions";
          data: PublicSimpleInstructionsData;
      }
    | {
          id: string;
          type: "FileUpload";
          data: FileUploadApiData;
      };
/*|  {
          number: number;
          type: "FileDownload";
          data: FileDownloadApiData;
      }
    
    | {
          number: number;
          type: "TextAndImage";
          data: TextAndImageApiData;
      }
    | {
          number: number;
          type: "TextProcessing";
          data: TextProcessingApiData;
      }
    | {
          number: number;
          type: "ImageOrVideoAttachment";
          data: ImageOrVideoAttachmentApiData;
      }; */

interface PublicSimpleTextData {
    imageUrl?: string;
    text: MultiLanguage<string>;
    audioUrl?: MultiLanguage<string>;
}

export interface LanguageMediaData {
    catalan: {
        language: string;
        shortLan: string;
        url: string;
    };
    [key: string]: {
        language: string;
        shortLan: string;
        url: string;
    };
}

export interface PublicSimpleVideoData {
    videos: { url: string; language: string; subtitles: string }[];
}

interface PublicSimpleInstructionsData {
    imageGallery: string[];
}

export interface FileUploadApiData {
    message: MultiLanguage<string>;
    imageUrl?: string;
    attachmentSent?: boolean;
}

/* interface FileDownloadApiData {}


interface TextAndImageApiData {}

interface TextProcessingApiData {}

interface ImageOrVideoAttachmentApiData {}
 */
export class Level {
    constructor() {}

    static async getLevelsOfProjectGroupAndTeam(
        teamSummary: ParticipantsTeamSummary
    ): Promise<{ [key: string]: PublicLevel }> {
        const response = await firebaseFunctions.httpsCallable(
            "levels-getTeamPublicLevelSummary"
        )({
            teamSummary: teamSummary,
        });
        return response.data as { [key: string]: PublicLevel };
    }

    static async getLevelSteps(
        projectId: string,
        levelId: string,
        teamId: string
    ): Promise<PublicLevelStep[]> {
        const response = await firebaseFunctions.httpsCallable(
            "levels-getPublicLevelSteps"
        )({
            projectId: projectId,
            levelId: levelId,
            teamId: teamId,
        });
        return response.data;
    }
}
