import { PublicLevel } from "./Levels";
import { ParticipantsTeamSummary } from "./ParticipantsTeam";
import { firebaseFunctions } from "../firebase";

export interface FullProject {
    title: string;
    id: number;
    summaryImage: string;
    backgroundImage: string;
    levels: Array<PublicLevel>;
}

export interface MultiLanguage<t> {
    [key: string]: t;
}

export interface PublicProjectInformation {
    id: string;
    groupId: string;
    teamId: string;

    projectName: MultiLanguage<string>;
    description: {
        shortDescription: MultiLanguage<string>;
        longDescription: MultiLanguage<string>;
    };
    urlImages?: {
        summaryImage: string;
        backgroundImage: string;
    };
    minVersion: number;
    difficulty: number;
    type: "robotics" | "programming" | "robotics and programming";
    roboticsTypes: string[];
    programmingTypes: string[];
    recommendedAges: [number, number];
    languages: {
        defaultLanguage: string;
        possibleLanguages: {
            [key: string]: "ok" | "pending";
        };
    };
    levels?: { [key: string]: PublicLevel };
}

export interface ProjectSummary {
    numberOfNotifications: number;
    imageUrl: string;
    title: string;
    id: number;
}
/* 
export interface Player {
    name: string;
    image: string;
    id: string;
}
 */
export class Project implements PublicProjectInformation {
    id: string;
    projectName: MultiLanguage<string>;
    description: {
        shortDescription: MultiLanguage<string>;
        longDescription: MultiLanguage<string>;
    };
    urlImages?: { summaryImage: string; backgroundImage: string } | undefined;
    minVersion: number;
    difficulty: number;
    type: "robotics" | "programming" | "robotics and programming";
    roboticsTypes: string[];
    programmingTypes: string[];
    recommendedAges: [number, number];
    languages: {
        defaultLanguage: string;
        possibleLanguages: { [key: string]: "ok" | "pending" };
    };
    groupId: string;
    teamId: string;
    levels?: { [key: string]: PublicLevel };

    constructor(publicInfo: PublicProjectInformation) {
        this.id = publicInfo.id;
        this.projectName = publicInfo.projectName;
        this.description = publicInfo.description;
        this.urlImages = publicInfo.urlImages;
        this.minVersion = publicInfo.minVersion;
        this.difficulty = publicInfo.difficulty;
        this.type = publicInfo.type;
        this.roboticsTypes = publicInfo.roboticsTypes;
        this.programmingTypes = publicInfo.programmingTypes;
        this.recommendedAges = publicInfo.recommendedAges;
        this.languages = publicInfo.languages;
        this.groupId = publicInfo.groupId;
        this.teamId = publicInfo.teamId;
        this.levels = publicInfo.levels;
    }

    static async getProjectsOfGroups(
        teamSummaries: ParticipantsTeamSummary[]
    ): Promise<PublicProjectInformation[]> {
        const response = await firebaseFunctions.httpsCallable(
            "projects-getGroupsProjectInformation"
        )({
            teamSummaries: teamSummaries,
        });
        return response.data as PublicProjectInformation[];
    }
}

/* 
    static updateCurrentProject = async (projectId: number) => {
        Project.currentProject = null;
        Project.currentProject = await ApiService.downloadProject(projectId);
    };

    static updateCurrentPlayers = () => {
        //TODO: Think the logic behind this
        Project.currentPlayers = [
            {
                name: "Maria",
                image: "https://i.imgur.com/LX4cXjE.png",
                id: "0",
            },
            {
                name: "Maria",
                image: "https://i.imgur.com/LX4cXjE.png",
                id: "0",
            },
            {
                name: "Maria",
                image: "https://i.imgur.com/LX4cXjE.png",
                id: "0",
            },
        ];
        return "Current Players updated";
    };

    static async getTeamProjectSummaries(
        teamsIds: string[]
    ): Promise<ProjectSummary[]> {
        const response = await firebase
            .functions()
            .httpsCallable("projects-getKidProjectsSummaries")({
            teamsIds: teamsIds,
        });
        return response.data() as ProjectSummary[];
    } */
/*     static currentProject: FullProject | null;
    static currentPlayers: Array<Player>;
*/
