import React, { useContext, useEffect, useState } from "react";
import { Store, StoreMainPage } from "../Service/Store";
import { AuthContext } from "./Auth";

export const StoreContext =
    React.createContext<StoreContextObject | null>(null);

interface StoreContextObject {
    storeMainPage?: StoreMainPage | null;
    updateStoreMainPage: () => Promise<StoreMainPage | null>;
}

export const StoreProvider = (props: { children: any }) => {
    const [currentContext, setCurrentContext] = useState<StoreContextObject>({
        updateStoreMainPage: updateStoreMainPage,
    });

    const authContext = useContext(AuthContext);

    useEffect(() => {
        updateStoreMainPage();
    }, [authContext?.userInfo]);

    async function updateStoreMainPage() {
        const storeMainPage = await Store.getStoreMainPage(
            authContext!.userInfo!.userId
        ).catch(() => {});

        if (storeMainPage) {
            setCurrentContext({
                ...currentContext,
                storeMainPage: storeMainPage,
            });

            return storeMainPage;
        } else {
            setCurrentContext({
                ...currentContext,
                storeMainPage: null,
            });

            return null;
        }
    }
    return (
        <StoreContext.Provider value={currentContext}>
            {props.children}
        </StoreContext.Provider>
    );
};
