import React from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import { HomeButton, UIButton, UIButtonProps } from "../../UI/UI";

const { height } = Dimensions.get("window");

interface ProjectFooterProps {
    homeButton?: boolean;
    centerButtons?: Array<UIButtonProps>;
    audioController?: JSX.Element;
    rightButton?: UIButtonProps;
}

export const ProjectFooter = (props: ProjectFooterProps) => {
    return (
        <View style={styles.footerContainer}>
            {props.homeButton ? (
                <View
                    style={[
                        styles.buttonSize,
                        {
                            position: "absolute",
                            left: 30,
                        },
                    ]}
                >
                    <HomeButton />
                </View>
            ) : null}
            {props.centerButtons?.map((val, ind) => {
                return (
                    <View
                        key={ind}
                        style={[
                            styles.buttonSize,
                            {
                                marginLeft: 10,
                                marginRight: 10,
                            },
                        ]}
                    >
                        <UIButton
                            key={ind}
                            image={val.image}
                            text={val.text}
                            bold
                            imagePercent={val.imagePercent}
                            onPress={val.onPress}
                        />
                    </View>
                );
            })}
            {props.audioController}
        </View>
    );
};

const styles = StyleSheet.create({
    footerContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
    },
    buttonSize: {
        height: height / 8,
        width: height / 8,
    },
});
