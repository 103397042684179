import React, { useContext, useEffect, useState } from "react";
import { KidInfo } from "src/Service/User";
import { AuthContext } from "./Auth";

export const ChildrenContext =
    React.createContext<ChildrenContextObject | null>(null);

export interface ChildrenContextObject {
    currentKidId: string | null;
    children?: KidInfo[];
    setCurrentKidId: (id: string) => void;
}

export const ChildrenProvider = (props: { children: any }) => {
    const [currentContext, setCurrentContext] = useState<ChildrenContextObject>(
        {
            currentKidId: null,
            setCurrentKidId: setCurrentKidId,
        }
    );
    const authContext = useContext(AuthContext);

    useEffect(() => {
        const childrenObject = authContext?.userInfo?.children;

        setCurrentContext({
            ...currentContext,
            children: childrenObject ? Object.values(childrenObject) : [],
        });
    }, [authContext]);

    function setCurrentKidId(id: string) {
        setCurrentContext((currentContext) => {
            return { ...currentContext, currentKidId: id };
        });
    }

    return (
        <ChildrenContext.Provider value={currentContext}>
            {props.children}
        </ChildrenContext.Provider>
    );
};
