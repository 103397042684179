import React from "react";
import { Dimensions, View, ScrollView, Image } from "react-native";

import { ProjectFooter } from "../GeneralComponents/ProjectFooter";

const { height, width } = Dimensions.get("window");

interface InstructionsProps {
    imageGallery: Array<string>;
    height: number;
}
export const Instructions = (props: InstructionsProps) => {
    const scrollViewWidth = width * 0.6;
    return (
        <View
            style={{
                width: width,
                height: props.height,
                flexDirection: "column",
            }}
        >
            <View
                style={{
                    flex: 80,
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <View
                    style={{
                        marginTop: 20,
                        width: scrollViewWidth,
                        height: "95%",
                        backgroundColor: "white",
                        borderRadius: 40,
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <View
                        style={{
                            width: scrollViewWidth * 0.9,
                            height: "100%",
                        }}
                    >
                        <ScrollView
                            horizontal
                            alwaysBounceHorizontal
                            pagingEnabled
                        >
                            {props.imageGallery.map((val, ind) => {
                                return (
                                    <View
                                        key={ind}
                                        style={{
                                            width: scrollViewWidth * 0.9,
                                            height: "90%",
                                        }}
                                    >
                                        <Image
                                            source={{
                                                uri: val,
                                            }}
                                            style={{
                                                flex: 1,
                                                resizeMode: "contain",
                                            }}
                                        ></Image>
                                    </View>
                                );
                            })}
                        </ScrollView>
                    </View>
                </View>
            </View>
            <View style={{ flex: 20 }}>
                <ProjectFooter homeButton />
            </View>
        </View>
    );
};
