// ResponsiveImage.ts https://stackoverflow.com/questions/39631895/how-to-set-image-width-to-be-100-and-height-to-be-auto-in-react-native

import React, { useEffect, useMemo, useState } from "react";
import {
    Image,
    ImageProps,
    ImageSourcePropType,
    ImageStyle,
    LayoutChangeEvent,
    StyleProp,
    StyleSheet,
    View,
    ViewProps,
    ViewStyle,
} from "react-native";

interface ImageAutoProps {
    src: ImageSourcePropType;
    srcWidth: number;
    srcHeight: number;
    containerProps?: ViewProps;
    imageProps?: Omit<ImageProps, "source">;
}

export const ImageAutoHeight: React.FC<ImageAutoProps> = (props) => {
    let containerStyle: StyleProp<ViewStyle> = {},
        imageStyle: StyleProp<ImageStyle> = {},
        containerProps: ViewProps = {},
        imageProps: Omit<ImageProps, "source"> = {};

    if (props.containerProps)
        ({ style: containerStyle, ...containerProps } = props.containerProps);

    if (props.imageProps)
        ({ style: imageStyle, ...imageProps } = props.imageProps);

    const [containerWidth, setContainerWidth] = useState<number>(0);

    const _onViewLayoutChange = (event: LayoutChangeEvent) => {
        const { width } = event.nativeEvent.layout;
        setContainerWidth(width);
    };

    const imageStyles = useMemo(() => {
        const ratio = containerWidth / props.srcWidth;
        return {
            width: containerWidth,
            height: props.srcHeight * ratio,
        };
    }, [containerWidth]);

    return (
        <View
            {...containerProps}
            style={[stylesAutoHeight.container, containerStyle]}
            onLayout={_onViewLayoutChange}
        >
            <Image
                {...imageProps}
                source={props.src}
                style={[imageStyles, imageStyle]}
            />
        </View>
    );
};

const stylesAutoHeight = StyleSheet.create({
    container: { width: "100%" },
});

export const ImageAutoWidth: React.FC<ImageAutoProps> = (props) => {
    let containerStyle: StyleProp<ViewStyle> = {},
        imageStyle: StyleProp<ImageStyle> = {},
        containerProps: ViewProps = {},
        imageProps: Omit<ImageProps, "source"> = {};

    if (props.containerProps)
        ({ style: containerStyle, ...containerProps } = props.containerProps);

    if (props.imageProps)
        ({ style: imageStyle, ...imageProps } = props.imageProps);

    const [containerHeight, setContainerHeight] = useState<number>(0);
    const _onViewLayoutChange = (event: LayoutChangeEvent) => {
        const { height } = event.nativeEvent.layout;
        setContainerHeight(height);
    };

    const imageStyles = useMemo(() => {
        const ratio = containerHeight / props.srcWidth;
        return {
            width: containerHeight,
            height: props.srcHeight * ratio,
        };
    }, [containerHeight]);

    return (
        <View
            {...containerProps}
            style={[stylesAutoWidth.container, containerStyle]}
            onLayout={_onViewLayoutChange}
        >
            <Image
                {...imageProps}
                source={props.src}
                style={[imageStyles, imageStyle]}
            />
        </View>
    );
};

const stylesAutoWidth = StyleSheet.create({
    container: { height: "100%" },
});
