import { useFocusEffect } from "@react-navigation/native";
import { Video } from "expo-av";
import React, { useEffect, useRef, useState } from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import { LanguageSelection } from "../../Modals/LanguageSelection";
import { UIButton } from "../../UI/UI";
import { LanguageService } from "../../../Service/LanguageService";
import { ProjectFooter } from "../GeneralComponents/ProjectFooter";
import { PublicSimpleVideoData } from "src/Service/Levels";
import { Loading } from "../../UI/Loading";

const { height, width } = Dimensions.get("window");

export interface SimpleVideoProps extends PublicSimpleVideoData {
    height: number;
    visible: boolean;
}

let video: Video;

export const SimpleVideo = (props: SimpleVideoProps) => {
    const [language, setLanguage] = useState<string>();
    const [modalVisible, setModalVisible] = useState(false);
    const [volume, setVolume] = useState(LanguageService.getAudioOnOff());

    const possibleLanguages = useRef(
        props.videos
            .filter((video) =>
                LanguageService.checkSupportedLanguage(video.language)
            )
            .map((video) => video.language)
    );

    useEffect(() => {
        const observer = { updateAudioLanguage, updateTextLanguage };
        LanguageService.subscribe(observer);
        let currentLanguage = LanguageService.getCurrentAudioLanguage();

        if (possibleLanguages.current.includes(currentLanguage))
            setLanguage(currentLanguage);
        else setLanguage(possibleLanguages.current[0]);

        return () => {
            LanguageService.unsubscribe(observer);
        };
    }, []);

    useFocusEffect(() => {
        const requestedLanguage = LanguageService.getCurrentAudioLanguage();
        if (
            !checkSameAudio(requestedLanguage) &&
            checkSupportAudio(requestedLanguage)
        )
            setLanguage(requestedLanguage);

        if (volume != LanguageService.getAudioOnOff())
            setVolume(LanguageService.getAudioOnOff());
        if (video && !props.visible) pauseVideo();
    });

    if (!language) return <Loading />;

    const volumeImage = volume
        ? require("../../../../assets/icons/volume.png")
        : require("../../../../assets/icons/mute.png");
    const volumeText = volume ? "ON" : "OFF";

    const audioShortLan =
        LanguageService.getShortNameOfLanguage(language).toUpperCase();

    async function updateAudioLanguage(language: string) {
        if (checkSameAudio(language) || !checkSupportAudio(language)) return;
        const status = await video.getStatusAsync();
        if (status.isLoaded) await video.unloadAsync();
        video.loadAsync({
            uri: props.videos.find((video) => video.language === language)!.url,
        });
        setLanguage(language);
    }

    function updateTextLanguage() {}

    function checkSameAudio(newLan: string): boolean {
        return language === newLan;
    }

    function checkSupportAudio(newLan: string): boolean {
        return (
            props.videos.findIndex((video) => video.language === newLan) !== -1
        );
    }

    function onVolumePressed() {
        let volume = LanguageService.getAudioOnOff();
        if (volume) muteVideo();

        LanguageService.setAudioOnOff(!volume);
        setVolume(!volume);
    }

    async function pauseVideo() {
        await video.pauseAsync();
        return;
    }

    async function playVideo() {
        await video.playAsync();
        return;
    }

    async function muteVideo() {
        if (video) video.setIsMutedAsync(true);
    }

    async function unmuteVideo() {
        if (video) video.setIsMutedAsync(false);
    }

    return (
        <View
            style={{
                width: width,
                height: props.height,
                flexDirection: "column",
            }}
        >
            <View
                style={{
                    flex: 80,
                    margin: 30,
                    marginLeft: 80,
                    marginRight: 80,
                }}
            >
                <Video
                    source={{
                        uri: props.videos.find(
                            (video) => video.language === language
                        )!.url,
                    }}
                    rate={1.0}
                    volume={1.0}
                    resizeMode="contain"
                    shouldPlay={false}
                    style={{ flex: 1 }}
                    useNativeControls
                    isMuted={!volume}
                    ref={(element: Video) => {
                        video = element;
                    }}
                />
            </View>
            <View style={{ flex: 20 }}>
                <ProjectFooter homeButton audioController={VideoController()} />
            </View>
        </View>
    );

    function VideoController() {
        return (
            <View style={videoControllerStyles.container}>
                <LanguageSelection
                    onClosed={() => setModalVisible(false)}
                    modalVisible={modalVisible}
                    possibleAudioLanguages={possibleLanguages.current}
                />
                <View style={videoControllerStyles.buttonSize}>
                    <UIButton
                        image={require("../../../../assets/icons/globe.png")}
                        text={audioShortLan}
                        bold
                        onPress={() => setModalVisible(true)}
                    />
                </View>
                <View style={videoControllerStyles.buttonSize}>
                    <UIButton
                        image={volumeImage}
                        text={volumeText}
                        bold
                        onPress={onVolumePressed}
                    />
                </View>
            </View>
        );
    }
};

const videoControllerStyles = StyleSheet.create({
    container: {
        flexDirection: "row",
    },
    buttonSize: {
        height: height / 8,
        width: height / 8,
        marginLeft: 10,
        marginRight: 10,
    },
});
