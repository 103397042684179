import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { View, StyleSheet, Image } from "react-native";

export const CardWithSmallBot = (props: { children: any }) => {
    return (
        <View style={smallStyles.container}>
            <View style={smallStyles.card}>
                {props.children}
                <Image
                    source={require("../../../assets/characters/Block.png")}
                    style={smallStyles.block}
                />
            </View>
        </View>
    );
};

export const CardWithBigBot = (props: {
    children?: any;
    childrenStyle?: StyleProp<ViewStyle>;
}) => {
    return (
        <View style={bigStyles.container}>
            <View style={bigStyles.card}>
                <View style={bigStyles.imageContainer}>
                    <Image
                        source={require("../../../assets/characters/Block.png")}
                        style={bigStyles.block}
                    />
                </View>
                <View
                    style={[bigStyles.childrenContainer, props.childrenStyle]}
                >
                    {props.children}
                </View>
            </View>
        </View>
    );
};

const smallStyles = StyleSheet.create({
    container: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    card: {
        width: "70%",
        height: "70%",
        backgroundColor: "white",
        borderRadius: 50,
        display: "flex",
        flexDirection: "column",
        padding: 30,
        maxWidth: 800,
        alignItems: "center",
    },
    block: {
        position: "absolute",
        height: "50%",
        width: "50%",
        resizeMode: "contain",
        left: "-20%",
        bottom: "-15%",
    },
});

const bigStyles = StyleSheet.create({
    container: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    card: {
        width: "80%",
        height: "80%",
        backgroundColor: "white",
        borderRadius: 50,
        display: "flex",
        flexDirection: "row",
        padding: 50,
    },
    block: {
        height: "80%",
        width: "80%",
        resizeMode: "contain",
    },
    childrenContainer: {
        flex: 1,
    },
    imageContainer: {
        flex: 1,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});
