import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { blue, white } from "../../Colors";
import { StoreZone } from "./StoreZone";
import { ConfigurationPage } from "./ConfigurationPage";
import { ParentsHomePage } from "./Home";

const Tab = createBottomTabNavigator();

export const ParentsZone = () => {
    return (
        <Tab.Navigator
            tabBarOptions={{
                activeTintColor: white,
                activeBackgroundColor: blue,
            }}
        >
            <Tab.Screen
                name="ParentsHome"
                component={ParentsHomePage}
                options={{
                    tabBarLabel: "Home",
                    tabBarIcon: ({ color, size }) => (
                        <MaterialCommunityIcons
                            name="home"
                            color={color}
                            size={size}
                        />
                    ),
                }}
            />
            <Tab.Screen
                name="Store"
                component={StoreZone}
                options={{
                    tabBarIcon: ({ color, size }) => (
                        <MaterialCommunityIcons
                            name="shopping"
                            color={color}
                            size={size}
                        />
                    ),
                }}
            />

            <Tab.Screen
                name="ParentsSettings"
                component={ConfigurationPage}
                options={{
                    tabBarLabel: "Settings",
                    tabBarIcon: ({ color, size }) => (
                        <MaterialCommunityIcons
                            name="cog"
                            color={color}
                            size={size}
                        />
                    ),
                }}
            />
        </Tab.Navigator>
    );
};
