import React, { useEffect, useRef, useState } from "react";
import {
    View,
    Text,
    Dimensions,
    Image,
    ScrollView,
    StyleSheet,
    ImageSourcePropType,
} from "react-native";
import { blue, green, purpleConstrast, red, white } from "../../../Colors";
import { LanguageSelection } from "../../Modals/LanguageSelection";
import { ColorButton } from "../../UI/ColorButton";
import {
    LanguageObserver,
    LanguageService,
} from "../../../Service/LanguageService";
import { FileUploadApiData, Level } from "../../../Service/Levels";
import { ProjectFooter } from "../GeneralComponents/ProjectFooter";
import { Loading } from "../../UI/Loading";
import * as DocumentPicker from "expo-document-picker";
import { getBase64 } from "../../../Service/Utils";
import { ParticipantsTeam } from "../../../Service/ParticipantsTeam";
import { Snackbar } from "react-native-paper";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

export interface FileUploadProps extends FileUploadApiData {
    levelId: string;
    height: number;
    image?: ImageSourcePropType;
    teamId: string;
}

const { width } = Dimensions.get("window");

export const FileUpload = (props: FileUploadProps) => {
    const [language, setLanguage] = useState<string>();
    const [languageSelection, setLanguageSelection] = useState(false);
    const [snackbar, setSnackbar] = useState<{
        message: string;
        visible: boolean;
        color?: string;
    }>({ message: "", visible: false });
    const [attachmentSent, setAttachmentSent] = useState(props.attachmentSent);

    const possibleTextLanguages = useRef(
        Object.getOwnPropertyNames(props.message).filter((lan) =>
            LanguageService.checkSupportedLanguage(lan)
        )
    );

    useEffect(() => {
        const observer: LanguageObserver = {
            updateAudioLanguage,
            updateTextLanguage,
        };
        LanguageService.subscribe(observer);
    });

    useEffect(() => {
        const requestedLanguage = LanguageService.getCurrentTextLanguage();
        if (
            !checkSameTextLan(requestedLanguage) &&
            checkTextLanSupport(requestedLanguage)
        )
            setLanguage(requestedLanguage);
        else if (!language) setLanguage(possibleTextLanguages.current[0]);
    });

    function checkSameTextLan(newLan: string) {
        return newLan == language;
    }

    function checkTextLanSupport(newLan: string) {
        return newLan in props.message;
    }

    function updateAudioLanguage() {
        null;
    }

    function updateTextLanguage(newLan: string) {
        if (!checkSameTextLan(newLan) && checkTextLanSupport(newLan))
            setLanguage(newLan);
    }

    async function selectAndSendFile() {
        const document = await DocumentPicker.getDocumentAsync();
        if (document.type === "cancel" || !document.file) return;
        try {
            const base64 = await getBase64(document.file);
            await ParticipantsTeam.sendSubmission(
                props.teamId,
                props.levelId,
                base64
            );
            setSnackbar({
                message: "File updated correctly!",
                visible: true,
                color: green,
            });
            setAttachmentSent(true);
        } catch {
            setSnackbar({
                message: "There was an error uploading the file!",
                visible: true,
                color: red,
            });
        }
    }

    if (!language) return <Loading />;
    const image: ImageSourcePropType =
        props.image ?? require("../../../../assets/characters/Block.png");

    const textShortLan =
        LanguageService.getShortNameOfLanguage(language).toUpperCase();

    return (
        <View style={{ width: width, height: props.height }}>
            <View style={styles.bodyContainer}>
                <View style={{ flex: 1 }}>
                    <Image source={image} style={styles.imageSize} />
                </View>
                <View style={styles.bubbleTextContainer}>
                    <View style={styles.bubble}>
                        <ScrollView
                            contentContainerStyle={styles.textScrollView}
                            persistentScrollbar
                        >
                            <Text style={styles.text}>
                                {props.message[language!]}
                            </Text>

                            {attachmentSent ? (
                                <>
                                    <MaterialCommunityIcons
                                        name="email-check"
                                        size={100}
                                        color={white}
                                    />
                                    <Text style={styles.smallText}>
                                        Arxiu enviat!
                                    </Text>
                                </>
                            ) : null}
                            <ColorButton
                                bColor={attachmentSent ? purpleConstrast : blue}
                                tColor={white}
                                text={
                                    attachmentSent
                                        ? "Envia un altre arxiu"
                                        : "Selecciona un arxiu"
                                }
                                onPress={() => selectAndSendFile()}
                                centerText
                                style={{ width: 250, marginTop: 10 }}
                                textStyle={{
                                    fontSize: 20,
                                    fontFamily: "Roboto_Bold",
                                }}
                            />
                        </ScrollView>
                    </View>
                </View>
            </View>
            <View
                style={{
                    flex: 20,
                }}
            >
                <ProjectFooter
                    homeButton
                    centerButtons={[
                        {
                            image: require("../../../../assets/icons/globe.png"),
                            onPress: () => setLanguageSelection(true),
                            text: textShortLan,
                        },
                    ]}
                />
            </View>
            <LanguageSelection
                modalVisible={languageSelection}
                possibleTextLanguages={possibleTextLanguages.current}
                onClosed={() => setLanguageSelection(false)}
            />
            <Snackbar
                visible={snackbar.visible}
                duration={3000}
                onDismiss={() =>
                    setSnackbar((snackbar) => {
                        return { ...snackbar, visible: false };
                    })
                }
                style={{ backgroundColor: snackbar.color }}
            >
                {snackbar.message}
            </Snackbar>
        </View>
    );
};

const styles = StyleSheet.create({
    bodyContainer: {
        flex: 80,
        display: "flex",
        flexDirection: "row",
        paddingLeft: 60,
        paddingRight: 60,
    },
    imageSize: {
        resizeMode: "contain",
        height: "100%",
        width: "100%",
    },
    bubbleTextContainer: {
        flex: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
    },
    bubble: {
        backgroundColor: "rgba(15,51,79,0.6)",
        height: "70%",
        width: "90%",
        borderRadius: 50,
        padding: 30,
        display: "flex",
        paddingTop: 50,
        paddingBottom: 50,
        justifyContent: "center",
    },
    textScrollView: {
        flexGrow: 1,
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        fontSize: 30,
    },
    text: {
        fontFamily: "Roboto_Bold",
        color: "white",
        fontSize: 30,
        lineHeight: 40,
        flexShrink: 1,
    },
    smallText: {
        fontFamily: "Roboto_Bold",
        color: "white",
        fontSize: 20,
        lineHeight: 40,
        flexShrink: 1,
    },
});
