import React, { useRef, useState } from "react";
import {
    ScrollView,
    TouchableOpacity,
    View,
    Text,
    StyleSheet,
} from "react-native";
import { Divider, Snackbar } from "react-native-paper";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

import { blue, lightGrey, white } from "../../Colors";
import { VerticalPage } from "./VerticalPage";
import { useNavigation } from "@react-navigation/core";

import firebase from "firebase/app";

export const ConfigurationPage = () => {
    const [snackbar, setSnackbar] = useState({
        message: "",
        active: false,
        color: blue,
    });
    const navigation = useNavigation();

    const configurationOptions = useRef<ConfigurationOption[]>([
        {
            iconName: "stars",
            title: "Qualifica'ns!",
            onPress: () => notAvailable(),
        },
        {
            iconName: "language",
            title: "Selecciona l'idioma",
            onPress: () => {},
        },
        {
            iconName: "chat",
            title: "Contacta amb nosaltres",
            onPress: () => notAvailable(),
        },
        {
            iconName: "robot",
            package: "FontAwesome5",
            title: "Sobre l'aplicació",
            onPress: () => notAvailable(),
        },
        {
            iconName: "verified-user",
            title: "Política de privacitat",
            onPress: () => notAvailable(),
        },
        {
            iconName: "file-contract",
            package: "FontAwesome5",
            title: "Termes i condicions",
            onPress: () => notAvailable(),
        },
        {
            iconName: "sign-out-alt",
            package: "FontAwesome5",
            title: "Tanca la sessió",
            onPress: () => {
                navigation.navigate("Welcome");
                firebase.auth().signOut();
            },
        },
    ]);

    function notAvailable() {
        setSnackbar({
            message: "You cannot do this right now. It will be operative soon",
            active: true,
            color: blue,
        });
    }

    return (
        <VerticalPage>
            <ScrollView>
                <View style={styles.container}>
                    <Text style={styles.headerText}>Configuració</Text>
                    <Divider
                        style={{
                            borderColor: blue,
                            borderWidth: 2,
                            width: "100%",
                        }}
                    />
                    <View style={[styles.card, styles.shadow]}>
                        {configurationOptions.current.map((option, ind) => (
                            <ConfigurationElement {...option} key={ind} />
                        ))}
                    </View>
                </View>
            </ScrollView>
            <Snackbar
                visible={snackbar.active}
                onDismiss={() => setSnackbar({ ...snackbar, active: false })}
                duration={3000}
                style={{ backgroundColor: snackbar.color }}
            >
                {snackbar.message}
            </Snackbar>
        </VerticalPage>
    );
};

interface ConfigurationOption {
    iconName: string;
    title: string;
    package?: "MaterialIcons" | "FontAwesome5";
    onPress: () => void;
}
const ConfigurationElement = (props: ConfigurationOption) => {
    return (
        <>
            <TouchableOpacity onPress={() => props.onPress()}>
                <View style={configElStyles.container}>
                    {props.package === "FontAwesome5" ? (
                        <FontAwesome5
                            name={props.iconName}
                            size={40}
                            color={blue}
                            style={configElStyles.leftIcon}
                        />
                    ) : (
                        <MaterialIcons
                            name={props.iconName}
                            size={40}
                            color={blue}
                            style={configElStyles.leftIcon}
                        />
                    )}
                    <Text style={configElStyles.text}>{props.title}</Text>

                    <MaterialIcons
                        name="keyboard-arrow-right"
                        size={40}
                        color={blue}
                        style={configElStyles.rightIcon}
                    />
                </View>
            </TouchableOpacity>

            <Divider style={configElStyles.divider} />
        </>
    );
};
const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 40,
        marginLeft: "10%",
        marginRight: "10%",
        position: "relative",
    },
    backButton: {
        position: "absolute",
        left: 0,
        top: -5,
    },
    headerText: {
        fontFamily: "Roboto_Medium",
        fontSize: 35,
        textAlign: "center",
        marginBottom: 20,
    },
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        elevation: 4,
    },
    card: {
        marginTop: 50,
        width: "100%",
        backgroundColor: white,
        borderRadius: 40,
        padding: 50,
        paddingTop: 20,
        paddingBottom: 40,
        marginBottom: 20,
    },
});

const configElStyles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: 10,
        marginRight: 10,
        marginTop: 30,
        marginBottom: 30,
        position: "relative",
    },
    leftIcon: {
        marginRight: 30,
    },
    text: {
        fontFamily: "Roboto_Medium",
        fontSize: 25,
        position: "absolute",
        left: 100,
    },
    rightIcon: {
        marginLeft: "auto",
    },
    divider: {
        height: 2,
        color: lightGrey,
    },
});
