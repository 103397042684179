import React, { useRef, useState } from "react";
import { useEffect } from "react";
import {
    StyleProp,
    TextStyle,
    View,
    ViewStyle,
    StyleSheet,
    Animated,
} from "react-native";

export const TextAnimator = (props: {
    style?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
    children: string;
    duration?: number;
    onFinish?: () => void;
}) => {
    const { style, textStyle, children, duration = 500, onFinish } = props;
    const [textArr, setTextArr] = useState<string[]>([]);
    const animatedValues = useRef<Animated.Value[]>([]);

    useEffect(() => {
        const textArr = children.trim().split(" ");
        const animatedArr = textArr.map((_, i) => new Animated.Value(0));
        setTextArr(textArr);
        animatedValues.current = animatedArr;
    }, []);

    function animated(toValue: number = 1) {
        const animations = textArr.map((_, index) =>
            Animated.timing(animatedValues.current[index], {
                toValue: toValue,
                duration: duration,
                useNativeDriver: false,
            })
        );

        Animated.stagger(duration / 5, animations).start(
            onFinish ? () => onFinish() : undefined
        );
    }

    if (textArr.length > 0) animated();

    return (
        <View style={[style, styles.textWrapper]}>
            {textArr.map((word, index) => (
                <Animated.Text
                    key={`${word} - ${index}`}
                    style={[
                        textStyle,
                        {
                            opacity: animatedValues.current[index],
                            transform: [
                                {
                                    translateY: Animated.multiply(
                                        animatedValues.current[index],
                                        new Animated.Value(5)
                                    ),
                                },
                            ],
                        },
                    ]}
                >
                    {word}
                    {`${index < textArr.length ? " " : ""}`}
                </Animated.Text>
            ))}
        </View>
    );
};

const styles = StyleSheet.create({
    textWrapper: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
    },
});
