import { useNavigation } from "@react-navigation/core";
import firebase from "firebase";
import React, { useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { AuthContext } from "../../provider/Auth";
import { darkGrey, green, red, white } from "../../Colors";
import { FullScreenBackground } from "../UI/UI";
import { CardWithBigBot } from "../UI/Card";
import { ColorButton } from "../UI/ColorButton";
import { TextAnimator } from "../UI/TextAnimator";

export const AccountFound = () => {
    const navigation = useNavigation();
    const authContext = useContext(AuthContext);

    const signOut = () => firebase.auth().signOut();

    const nextPage = () => {
        navigation.navigate("RegisterChildren");
    };

    useEffect(() => {
        if (!authContext?.currentUser) navigation.navigate("Welcome");
    }, [authContext]);

    return (
        <FullScreenBackground
            source={require("../../../assets/backgrounds/children.jpg")}
        >
            <CardWithBigBot childrenStyle={styles.centerElements}>
                <TextAnimator
                    textStyle={styles.explanation}
                    style={styles.explanationContainer}
                >
                    Ei! Hem trobat un compte amb el qual vas començar a
                    registrar-te però no vas acabar el procés. T'agradaria
                    fer-ho ara?
                </TextAnimator>
                <View style={styles.buttonsContainer}>
                    <ColorButton
                        bColor={green}
                        tColor={white}
                        text="Sí, acabem el registre!"
                        onPress={() => nextPage()}
                        style={styles.button}
                    />

                    <ColorButton
                        bColor={red}
                        tColor={white}
                        text="No, tanca la sessió"
                        onPress={() => signOut()}
                        style={styles.button}
                    />
                </View>
            </CardWithBigBot>
        </FullScreenBackground>
    );
};

const styles = StyleSheet.create({
    explanation: {
        fontFamily: "Roboto_Light",
        fontSize: 25,
        color: darkGrey,
        textAlign: "left",
    },
    explanationContainer: {
        marginBottom: 50,
    },
    buttonsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    button: {
        marginBottom: 20,
    },
    centerElements: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});
