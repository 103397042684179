import { ImageSourcePropType } from "react-native";

export interface LanguageIcon {
    language: string;
    nativeName: string;
    shortLan: string;
    image: ImageSourcePropType;
}

export interface LanguageObserver {
    updateAudioLanguage: (language: string) => void;
    updateTextLanguage: (language: string) => void;
    updateVolume?: (volume: boolean) => void;
}

export class LanguageService {
    private static audioLanguage = "catalan";
    private static textLanguage = "catalan";
    private static audioOnOff = true;
    private static possibleLanguages: { [key: string]: LanguageIcon } = {
        catalan: {
            language: "catalan",
            nativeName: "català",
            shortLan: "cat",
            image: require("../../assets/icons/languages/cat-flag.png"),
        },
        spanish: {
            language: "spanish",
            nativeName: "español",
            shortLan: "esp",
            image: require("../../assets/icons/languages/esp-flag.png"),
        },
        galician: {
            language: "galician",
            nativeName: "galego",
            shortLan: "gal",
            image: require("../../assets/icons/languages/gal-flag.png"),
        },
        basque: {
            language: "basque",
            nativeName: "euskara",
            shortLan: "eus",
            image: require("../../assets/icons/languages/eus-flag.png"),
        },
        english: {
            language: "english",
            nativeName: "english",
            shortLan: "eng",
            image: require("../../assets/icons/languages/eng-flag.png"),
        },
    };

    private static observers: LanguageObserver[] = [];

    static subscribe(observer: LanguageObserver) {
        this.observers.push(observer);
    }

    static unsubscribe(observer: LanguageObserver) {
        const index = this.observers.indexOf(observer);
        this.observers.splice(index);
    }

    static setCurrentAudioLanguage(language: string) {
        this.audioLanguage = language;
        this.observers.forEach((val) => {
            val.updateAudioLanguage(this.audioLanguage);
        });
    }

    static getShortNameOfLanguage(language: string) {
        if (!(language in this.possibleLanguages))
            throw "This language is not supported";
        return this.possibleLanguages[language].shortLan;
    }

    static getCurrentAudioLanguage(): string {
        return this.audioLanguage;
    }

    static setCurrentTextLanguage(language: string) {
        this.textLanguage = language;
        this.observers.forEach((val) =>
            val.updateTextLanguage(this.textLanguage)
        );
    }

    static getCurrentTextLanguage(): string {
        return this.textLanguage;
    }

    static getLanguage(language: string): LanguageIcon {
        if (!(language in this.possibleLanguages))
            throw `${language} is not a supported language`;
        else return this.possibleLanguages[language];
    }

    static getLanguages(languages: string[]): Array<LanguageIcon> {
        let languagesResponse: LanguageIcon[] = [];
        languages.forEach((language, ind) => {
            if (!(language in this.possibleLanguages))
                throw `${language} is not a supported language`;
            else languagesResponse.push(this.possibleLanguages[language]);
        });
        return languagesResponse;
    }

    static checkSupportedLanguage(language: string): boolean {
        return language in LanguageService.possibleLanguages;
    }

    static getAudioOnOff() {
        return this.audioOnOff;
    }

    static setAudioOnOff(onOff: boolean) {
        this.audioOnOff = onOff;
        this.observers.forEach((obs) => {
            return obs.updateVolume ? obs.updateVolume(onOff) : undefined;
        });
    }
}
