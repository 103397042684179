import firebase from "firebase/app";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAda0mS9F8zE-cgOuTxwILmm348FWM9R0c",
    authDomain: "roboclick-15109.firebaseapp.com",
    projectId: "roboclick-15109",
    storageBucket: "roboclick-15109.appspot.com",
    messagingSenderId: "648663652774",
    appId: "1:648663652774:web:e38dd86117ea6b892c9767",
    measurementId: "G-S56X3F1CZ0",
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
/*     firebase.functions().useEmulator("192.168.0.15", 5001);
    firebase.auth().useEmulator("http://192.168.0.15:9099");
 */

export const firebaseFunctions = firebaseApp.functions("europe-west1");
