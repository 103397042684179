import {
    Image,
    ImageBackground,
    ImageSourcePropType,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from "react-native";
import React, { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import { mediumGrey, yellow } from "../../Colors";

export interface UIButtonProps {
    onPress?: Function;
    text?: string;
    image: ImageSourcePropType;
    textColor?: string;
    bold?: boolean;
    imagePercent?: number;
    tintColor?: string;
}
export const UIButton = (props: UIButtonProps) => {
    let imagePercent = props.text ? 70 : 100;

    if (props.imagePercent) imagePercent = imagePercent * props.imagePercent;

    const imagePercentString = imagePercent.toString().concat("%");

    return (
        <TouchableOpacity
            style={styles.buttonContainer}
            onPress={() => (props.onPress ? props.onPress() : null)}
        >
            <Image
                source={props.image}
                style={[
                    {
                        height: imagePercentString,
                        width: imagePercentString,
                    },
                    props.tintColor ? { tintColor: props.tintColor } : null,
                ]}
            ></Image>
            {props.text ? (
                <Text
                    style={[
                        {
                            fontFamily: props.bold ? "Roboto_Bold" : "Roboto",
                            color: props.textColor ? props.textColor : "white",
                        },
                        styles.buttonText,
                    ]}
                >
                    {props.text}
                </Text>
            ) : null}
        </TouchableOpacity>
    );
};

export const HomeButton = () => {
    const navigation = useNavigation();
    return (
        <UIButton
            image={require("../../../assets/icons/home-icon.png")}
            onPress={() => navigation.navigate("Home")}
        />
    );
};

export const Star = (props: { color: string }) => {
    const [size, setSize] = useState<number>();
    return (
        <View
            style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
            }}
            onLayout={(ev) => {
                setSize(ev.nativeEvent.layout.height);
            }}
        >
            {size ? (
                <FontAwesome
                    name="star"
                    color={props.color}
                    adjustsFontSizeToFit
                    numberOfLines={1}
                    size={size}
                />
            ) : undefined}
        </View>
    );
};

export const Stars = (props: { yellowStars?: number; starsNumber: number }) => {
    let starsComponentArray = [];
    let passed = props.yellowStars ?? 0;

    for (let i = 0; i < props.starsNumber; i++) {
        if (i < passed) {
            starsComponentArray.push(<Star key={i} color={yellow} />);
            continue;
        }
        starsComponentArray.push(<Star key={i} color={mediumGrey} />);
    }
    return (
        <View
            style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100%",
                paddingTop: "2%",
                paddingBottom: "2%",
            }}
        >
            {starsComponentArray}
        </View>
    );
};

export const FullScreenBackground = (props: {
    source?: ImageSourcePropType;
    children?: any;
}) => {
    const { source, children } = props;
    return (
        <ImageBackground
            source={source ?? require("../../../assets/backgrounds/green.png")}
            style={{ flex: 1 }}
        >
            {children}
        </ImageBackground>
    );
};

const styles = StyleSheet.create({
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    buttonText: {
        fontSize: 20,
        marginTop: 5,
    },
});
