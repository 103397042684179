import { useNavigation } from "@react-navigation/core";
import React, { useContext } from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import { AuthContext } from "../../provider/Auth";
import { green, red, white } from "../../Colors";
import { FullScreenBackground } from "../UI/UI";
import { ColorButton } from "../UI/ColorButton";
import { TextAnimator } from "../UI/TextAnimator";

export const Tour = () => {
    const navigation = useNavigation();
    const authContext = useContext(AuthContext);
    const onYesPressed = () => {
        navigateNext();
    };
    const onNoPressed = () => {
        navigateNext();
    };

    const navigateNext = () => {
        navigation.navigate("SignIn");
    };
    return (
        <FullScreenBackground
            source={require("../../../assets/backgrounds/children.jpg")}
        >
            <View style={styles.container}>
                <View style={styles.card}>
                    <View style={styles.imageContainer}>
                        <Image
                            source={require("../../../assets/characters/Block.png")}
                            style={styles.image}
                        />
                    </View>
                    <View style={styles.instructionsContainer}>
                        <TextAnimator
                            textStyle={styles.instructionsText}
                            style={styles.instructionsTextContainer}
                            duration={500}
                        >
                            Abans de començar... Vols que t'expliqui com
                            funciona i per a què serveix l'aplicació?
                        </TextAnimator>
                        <ColorButton
                            bColor={green}
                            tColor={white}
                            onPress={onYesPressed}
                            text={"Sí, sisplau!"}
                            style={styles.colorButton}
                            centerText
                        />
                        <ColorButton
                            bColor={red}
                            tColor={white}
                            onPress={onNoPressed}
                            text={"No, gràcies!"}
                            style={styles.colorButton}
                            centerText
                        />
                    </View>
                </View>
            </View>
        </FullScreenBackground>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    card: {
        width: "80%",
        height: "80%",
        backgroundColor: "white",
        borderRadius: 50,
        display: "flex",
        flexDirection: "row",
        padding: 30,
    },
    imageContainer: { flex: 1, margin: 40 },
    image: { width: "100%", height: "100%", resizeMode: "contain" },
    instructionsContainer: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    instructionsTextContainer: {
        marginBottom: 40,
    },
    instructionsText: {
        fontSize: 25,
        fontFamily: "Roboto_Light",
        textAlign: "justify",
    },
    colorButton: {
        marginBottom: 20,
        width: "80%",
        paddingTop: 15,
        paddingBottom: 15,
    },
});
