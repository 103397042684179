import React, { useEffect, useState } from "react";
import { View, Animated, Image } from "react-native";
import { FullScreenBackground } from "./UI";

/** Returns the image of a friendly robot blinking  */
export const Loading = (props: {
    small?: boolean;
    background?: boolean;
    fullScreen?: boolean;
}) => {
    const opacity = useState(new Animated.Value(1))[0];

    useEffect(() => fadeOut());

    function fadeIn() {
        Animated.timing(opacity, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true,
        }).start(fadeOut);
    }
    function fadeOut() {
        Animated.timing(opacity, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true,
        }).start(fadeIn);
    }

    return (
        <Background background={props.background}>
            <View
                style={[
                    {
                        justifyContent: "center",
                        alignItems: "center",
                    },
                    props.fullScreen
                        ? { flex: 1 }
                        : !props.small
                        ? { height: 300, width: 300 }
                        : { height: 150, width: 150 },
                ]}
            >
                <Animated.View
                    style={{
                        opacity,
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Image
                        style={{
                            height: "60%",
                            width: "50%",
                            resizeMode: "contain",
                        }}
                        source={require("../../../assets/characters/Block.png")}
                    />
                </Animated.View>
            </View>
        </Background>
    );
};
const Background = (props: { background?: boolean; children?: any }) => {
    if (props.background)
        return <FullScreenBackground>{props.children}</FullScreenBackground>;
    return props.children;
};
