import React, { useState } from "react";
import {
    Dimensions,
    Modal,
    StyleSheet,
    Text,
    TouchableHighlight,
    View,
    Image,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { AvatarEl } from "../KidZone/HomeScreen/Header/Avatar";
import { ApiService } from "../../Service/ApiService";

const { width, height } = Dimensions.get("window");
const avatarSize = width / 7;

let avatarsArray: Array<AvatarEl>;

export const AvatarSelection = (props: {
    visible: boolean;
    onClosed: Function;
}) => {
    const [loaded, setLoaded] = useState(false);

    if (!loaded) {
        ApiService.getAllAvatars().then((res) => {
            avatarsArray = res;
            setLoaded(true);
        });
        return null;
    }

    return (
        <Modal animationType="fade" transparent={true} visible={props.visible}>
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <Text style={styles.modalTitle}>TRIA EL TEU AVATAR</Text>
                    <ScrollView style={{ maxHeight: height / 2 }}>
                        <View style={styles.gridContainer}>
                            {avatarsArray.map((val, index) => {
                                return (
                                    <TouchableHighlight
                                        key={index}
                                        style={styles.avatarImageContainer}
                                        onPress={() => {
                                            //TODO: What if the save is not correct?
                                            ApiService.saveAvatarImage(val);
                                            props.onClosed();
                                        }}
                                    >
                                        <Image
                                            style={{
                                                height: "70%",
                                                width: "70%",
                                            }}
                                            source={{
                                                uri: val.url,
                                            }}
                                        ></Image>
                                    </TouchableHighlight>
                                );
                            })}
                        </View>
                    </ScrollView>
                    <TouchableHighlight
                        style={{
                            ...styles.closeButton,
                            backgroundColor: "#2196F3",
                        }}
                        onPress={() => {
                            props.onClosed();
                        }}
                    >
                        <Text style={styles.textStyle}>OK</Text>
                    </TouchableHighlight>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    modalView: {
        margin: 20,
        backgroundColor: "rgba(240,240,240,1)",
        borderRadius: 20,
        padding: 25,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    closeButton: {
        width: width / 10,
        marginTop: 20,
        backgroundColor: "#F194FF",
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
    },
    modalTitle: {
        fontFamily: "Roboto_Bold",
        fontSize: 50,
        marginBottom: 15,
        textAlign: "center",
        color: "rgba(85,85,85,1)",
    },
    gridContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        width: avatarSize * 4.6,
        maxWidth: 900,
    },
    avatarImageContainer: {
        margin: 10,
        width: avatarSize,
        maxWidth: 200,
        maxHeight: 200,
        height: avatarSize,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        elevation: 2,
        borderRadius: avatarSize / 2,
        borderWidth: 2,
        borderColor: "rgba(68, 114, 196, 1)",
        backgroundColor: "rgba(255,255,255,1)",
    },
});
