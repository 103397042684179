import { useNavigation } from "@react-navigation/native";
import React from "react";

import { ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import { PublicProjectInformation } from "../../../Service/Project";

import { ProjectPreview } from "../GeneralComponents/ProjectPreview";

/** A function that returns an array of project summaries in a horizontal scrollview
 * @param projects Array of Project Summaries to show
 * @returns A horizontal scrollview with all the provided project summaries, with its image and title and,
 *  if notifications > 0, a warning with the outstanding notifications
 */

export const ProjectScrollView = (props: {
    projects: Array<PublicProjectInformation>;
}) => {
    const navigation = useNavigation();

    return (
        <ScrollView horizontal alwaysBounceHorizontal style={styles.container}>
            {props.projects.map((val, index) => {
                return (
                    <TouchableOpacity
                        style={styles.projectPreview}
                        key={index}
                        onPress={() => {
                            navigation.navigate("LevelSelection", {
                                teamSummary: {
                                    teamId: val.teamId,
                                    projectId: val.id,
                                    groupId: val.groupId,
                                },
                            });
                        }}
                    >
                        <ProjectPreview project={val}></ProjectPreview>
                    </TouchableOpacity>
                );
            })}
        </ScrollView>
    );
};

/** The stylesheet of the ProjectScrollView */
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    projectPreview: {
        marginLeft: 60,
        justifyContent: "center",
        flex: 1,
    },
});
