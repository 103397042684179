import React from "react";
import { View, Image, Text, Platform } from "react-native";
import { white } from "../../Colors";
import { WebView } from "react-native-webview";

export const StoreSuccessPage = () => {
    return (
        <View
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <View
                style={{
                    backgroundColor: white,
                    height: 500,
                    width: 500,
                    borderRadius: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {Platform.OS === "web" ? (
                    <Image
                        source={require("../../../assets/characters/cheer.svg")}
                        style={{
                            width: 300,
                            height: 300,
                        }}
                    />
                ) : (
                    <WebView
                        scalesPageToFit={false}
                        originWhitelist={["*"]}
                        javaScriptEnabled={true}
                        startInLoadingState={true}
                        domStorageEnabled={true}
                        source={require("../../../assets/characters/cheer.svg")}
                        allowsFullscreenVideo={false}
                        style={{
                            width: 300,
                            height: 300,
                        }}
                    />
                )}

                {/*                 <Video
                    source={require("../../../assets/characters/cheer.mp4")}
                    resizeMode="contain"
                    useNativeControls={false}
                    isLooping
                    style={{ width: 200, height: 200 }}
                    isMuted
                    shouldPlay
                />
 */}
                <Text>Nou projecte aconseguit!</Text>
            </View>
        </View>
    );
};
