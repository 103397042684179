import React, { useContext, useState } from "react";
import {
    View,
    Text,
    StyleSheet,
    Image,
    TouchableOpacity,
    Modal,
    TextInput,
    Platform,
    useWindowDimensions,
    KeyboardAvoidingView,
    ScrollView,
} from "react-native";
import { ImageAutoWidth } from "../../utils/ImageSize";
import {
    blue,
    darkGrey,
    green,
    highOpacityGrey,
    red,
    white,
} from "../../Colors";
import { FullScreenBackground } from "../UI/UI";
import { ColorButton } from "../UI/ColorButton";
import { ActivityIndicator, Snackbar } from "react-native-paper";
import { Picker } from "@react-native-picker/picker";
import { spain } from "../../../assets/provinces.json";
import { useNavigation } from "@react-navigation/core";
import { months } from "../../../assets/datePicker/cat.json";
import { getAge } from "../../utils/Age";
import {
    KidBasicInfo,
    KidInfo,
    ParentInformation,
    User,
    UserInformation,
} from "../../Service/User";
import { AuthContext } from "../../provider/Auth";
import { v4 as uuidv4 } from "uuid";
import { Loading } from "../UI/Loading";

export const RegisterChildren = () => {
    const [children, setChildren] = useState<KidBasicInfo[]>([]);
    const [province, setProvince] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [fatalError, setFatalError] = useState("");
    const navigation = useNavigation();

    const authContext = useContext(AuthContext);

    const changeChildren = (children: KidBasicInfo[]) => setChildren(children);
    const onSave = async () => {
        if (children && province && authContext?.currentUser?.uid) {
            setLoading(true);
            try {
                setFatalError("");
                const parentId = uuidv4();

                const childrenIds = children.map(() => uuidv4());

                const parent: ParentInformation = {
                    email: authContext.currentUser.email!,
                    parentId: parentId,
                    childrenIds: childrenIds,
                };

                await User.createNewParent(parent);

                let formattedChildren: { [key: string]: KidInfo } = {};

                children.forEach(
                    (kid, ind) =>
                        (formattedChildren[childrenIds[ind]] = {
                            ...kid,
                            kidId: childrenIds[ind],
                            permissions: {
                                commercialInformation: false,
                                imageRights: false,
                            },
                        })
                );
                const user: UserInformation = {
                    children: formattedChildren,
                    userId: uuidv4(),
                    parentsIds: [parent.parentId],
                    province: province,
                    authUid: [authContext?.currentUser?.uid],
                };
                await User.createUserFromAuthUid(user);
                authContext.updateUser(authContext.currentUser);
            } catch {
                setFatalError(
                    "There is a problem. Please try it again later or contact the admin"
                );
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <FullScreenBackground
            source={require("../../../assets/backgrounds/children.jpg")}
        >
            <View style={styles.container}>
                <View style={styles.card}>
                    <Text style={styles.question}>
                        Qui vols que utilitzi l'aplicació?
                    </Text>
                    <ChildrenSelection
                        children={children}
                        onChildrenChange={changeChildren}
                    />
                    {children.length > 0 ? (
                        <>
                            <Text style={styles.question}>
                                A quina província viviu?
                            </Text>
                            <Picker
                                selectedValue={province}
                                onValueChange={(itemValue, ind) =>
                                    setProvince(itemValue as string)
                                }
                                style={styles.picker}
                                itemStyle={styles.twoPickerItems}
                            >
                                {spain.map((val, ind) => (
                                    <Picker.Item
                                        label={val}
                                        value={val}
                                        key={`${val} - ${ind}`}
                                    />
                                ))}
                            </Picker>
                        </>
                    ) : undefined}
                    {children.length > 0 && province ? (
                        <ColorButton
                            bColor={blue}
                            tColor={white}
                            onPress={() => onSave()}
                            text="Perfecte!"
                            style={styles.saveButton}
                            disabled={loading}
                        />
                    ) : null}
                    {loading ? <ActivityIndicator color={blue} /> : null}
                    {fatalError ? <Text>{fatalError}</Text> : null}
                    <Image
                        source={require("../../../assets/characters/Block.png")}
                        style={styles.block}
                    />
                </View>
            </View>
        </FullScreenBackground>
    );
};

const ChildrenSelection = (props: {
    children: KidBasicInfo[];
    onChildrenChange: (children: KidBasicInfo[]) => void;
}) => {
    const { children, onChildrenChange } = props;
    const [newKid, setNewKid] = useState(false);

    const closeNewModal = (kid?: KidBasicInfo) => {
        setNewKid(false);
        if (kid) onChildrenChange([...children, kid]);
    };

    const onKidEdit = (kid: KidBasicInfo, ind: number) => {
        const copiedChildren = [...children];
        copiedChildren[ind] = kid;
        onChildrenChange(copiedChildren);
    };

    const openModal = () => setNewKid(true);
    return (
        <View style={styles.childrenCarousel}>
            {children.map((kid, ind) => (
                <Kid
                    kid={kid}
                    key={`${kid.name} - ${ind}`}
                    onKidEdit={onKidEdit}
                    ind={ind}
                />
            ))}
            <TouchableOpacity
                style={styles.plusContainer}
                onPress={() => openModal()}
            >
                <ImageAutoWidth
                    imageProps={{ style: styles.plus }}
                    srcHeight={256}
                    srcWidth={256}
                    src={require("../../../assets/icons/plus.png")}
                />
            </TouchableOpacity>
            {newKid ? (
                <EditKid closeModal={closeNewModal} visible={newKid} />
            ) : undefined}
        </View>
    );
};

const Kid = (props: {
    kid: KidBasicInfo;
    ind: number;
    onKidEdit: (kid: KidBasicInfo, ind: number) => void;
}) => {
    const { kid, ind, onKidEdit } = props;
    const [editKid, setEditKid] = useState(false);

    const closeEditKid = (kid?: KidBasicInfo) => {
        if (kid) onKidEdit(kid, ind);
        setEditKid(false);
    };
    return (
        <View style={styles.kidContainer}>
            <TouchableOpacity onPress={() => setEditKid(true)}>
                <View style={styles.kidImageContainer}>
                    <ImageAutoWidth
                        src={
                            kid.gender === "male"
                                ? require("../../../assets/icons/male_kid.png")
                                : require("../../../assets/icons/female_kid.png")
                        }
                        imageProps={{ style: styles.kidImage }}
                        srcWidth={512}
                        srcHeight={512}
                    />
                </View>
                <Text style={styles.kidNameText}>{kid.name}</Text>
                <Text style={styles.kidAgeText}>{`${getAge(
                    kid.birthDate
                )} years`}</Text>
            </TouchableOpacity>
            {editKid ? (
                <EditKid
                    visible={editKid}
                    kid={kid}
                    closeModal={closeEditKid}
                />
            ) : undefined}
        </View>
    );
};

const EditKid = (props: {
    kid?: KidBasicInfo;
    visible: boolean;
    closeModal: (kid?: KidBasicInfo) => void;
}) => {
    const [gender, setGender] = useState(props.kid?.gender);
    const [name, setName] = useState(props.kid?.name);
    const [surname, setSurname] = useState(props.kid?.surname);
    const [day, setDay] = useState<number>(
        new Date(props.kid?.birthDate ?? Date.now()).getDate()
    );
    const [month, setMonth] = useState<number>(
        new Date(props.kid?.birthDate ?? Date.now()).getMonth()
    );
    const [year, setYear] = useState<number>(
        new Date(props.kid?.birthDate ?? Date.now()).getFullYear()
    );

    const [snackbarV, setSnackbarV] = useState(false);

    const onSave = () => {
        if (name && surname && gender && day && month && year)
            return props.closeModal({
                name: name,
                surname: surname,
                birthDate: new Date(year, month, day).getTime(),
                gender: gender,
            });
        else toggleSnackbar();
    };
    const onDiscard = () => props.closeModal();

    const onDismissSnackbar = () => setSnackbarV(false);
    const toggleSnackbar = () => setSnackbarV(true);

    function getMonth(month: number) {
        return months[month];
    }

    return (
        <Modal animationType="fade" transparent visible={props.visible}>
            <View style={modalStyles.modal}>
                <View style={modalStyles.editKidContainer}>
                    <View style={modalStyles.nameInputContainer}>
                        <TextInput
                            placeholder={"Nom"}
                            onChangeText={(t) => setName(t)}
                            style={[
                                modalStyles.editTextTitle,
                                modalStyles.alignRight,
                            ]}
                            value={name}
                        />
                        <TextInput
                            placeholder={"Cognom"}
                            onChangeText={(t) => setSurname(t)}
                            style={[
                                modalStyles.editTextTitle,
                                modalStyles.alignLeft,
                            ]}
                            value={surname}
                        />
                    </View>
                    <View style={modalStyles.selectKidContainer}>
                        <TouchableOpacity onPress={() => setGender("male")}>
                            <ImageAutoWidth
                                imageProps={{
                                    style: [
                                        modalStyles.selectKidImage,
                                        gender === "male"
                                            ? modalStyles.active
                                            : modalStyles.inactive,
                                    ],
                                }}
                                src={require("../../../assets/icons/male_kid.png")}
                                srcHeight={512}
                                srcWidth={512}
                            />
                            <Text
                                style={[
                                    modalStyles.label,
                                    gender === "male"
                                        ? modalStyles.active
                                        : modalStyles.inactive,
                                ]}
                            >
                                Nen
                            </Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setGender("female")}>
                            <ImageAutoWidth
                                imageProps={{
                                    style: [
                                        modalStyles.selectKidImage,
                                        gender === "female"
                                            ? modalStyles.active
                                            : modalStyles.inactive,
                                    ],
                                }}
                                src={require("../../../assets/icons/female_kid.png")}
                                srcHeight={512}
                                srcWidth={512}
                            />
                            <Text
                                style={[
                                    modalStyles.label,
                                    gender === "female"
                                        ? modalStyles.active
                                        : modalStyles.inactive,
                                ]}
                            >
                                Nena
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <View style={modalStyles.editAgeContainer}>
                        <Text style={modalStyles.editTextAge}>
                            Va néixer el
                        </Text>
                        <Picker
                            selectedValue={day}
                            onValueChange={(itemValue, ind) =>
                                setDay(+itemValue)
                            }
                            style={{ width: 100 }}
                            itemStyle={styles.twoPickerItems}
                        >
                            {[...Array(31)].map((e, i) => (
                                <Picker.Item
                                    value={i + 1}
                                    label={(i + 1).toString()}
                                    key={i}
                                />
                            ))}
                        </Picker>
                        <Text style={modalStyles.editTextAge}>de</Text>
                        <Picker
                            selectedValue={month}
                            onValueChange={(itemValue, ind) =>
                                setMonth(+itemValue)
                            }
                            style={{ width: 150 }}
                            itemStyle={styles.twoPickerItems}
                        >
                            {[...Array(12)].map((e, i) => (
                                <Picker.Item
                                    value={i + 1}
                                    label={getMonth(i)}
                                    key={i}
                                />
                            ))}
                        </Picker>
                        <Text style={modalStyles.editTextAge}>de</Text>
                        <Picker
                            selectedValue={year}
                            onValueChange={(itemValue, ind) =>
                                setYear(+itemValue)
                            }
                            style={{ width: 100 }}
                            itemStyle={styles.twoPickerItems}
                        >
                            {[...Array(20)].map((e, i) => {
                                const num =
                                    new Date(Date.now()).getFullYear() - 19 + i;
                                return (
                                    <Picker.Item
                                        value={num}
                                        label={num.toString()}
                                        key={i}
                                    />
                                );
                            })}
                        </Picker>
                    </View>
                    <View style={modalStyles.editButtonsContainer}>
                        <ColorButton
                            bColor={green}
                            tColor={white}
                            onPress={() => onSave()}
                            text="Perfecte!"
                            style={{ marginRight: 20 }}
                        />
                        <ColorButton
                            bColor={red}
                            tColor={white}
                            onPress={() => onDiscard()}
                            text="Descarta!"
                        />
                    </View>
                </View>
                <Snackbar
                    visible={snackbarV}
                    onDismiss={onDismissSnackbar}
                    duration={3000}
                    style={{ backgroundColor: red }}
                >
                    Has d'emplenar el nom, clicar si és un nen o una nena i en
                    quina data va néixer
                </Snackbar>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    card: {
        width: "70%",
        backgroundColor: "white",
        borderRadius: 50,
        display: "flex",
        flexDirection: "column",
        padding: 30,
        maxWidth: 800,
        alignItems: "center",
    },
    block: {
        position: "absolute",
        height: "50%",
        width: "50%",
        resizeMode: "contain",
        left: "-20%",
        bottom: "-15%",
    },
    question: {
        textAlign: "center",
        fontSize: 20,
        fontFamily: "Roboto_Medium",
    },
    childrenCarousel: {
        display: "flex",
        flexDirection: "row",
        margin: 50,
        alignItems: "center",
        justifyContent: "center",
        height: 200,
    },
    kidContainer: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 30,
    },
    plusContainer: {
        height: "40%",
    },
    plus: {
        resizeMode: "contain",
    },
    kidImageContainer: {
        height: "70%",
    },
    kidImage: {
        resizeMode: "contain",
    },
    kidNameText: {
        fontSize: 20,
        fontFamily: "Roboto_Light",
        textAlign: "center",
        padding: 5,
    },
    kidAgeText: {
        fontSize: 15,
        fontFamily: "Roboto_Light",
        textAlign: "center",
        padding: 5,
    },
    twoPickerItems: {
        height: 120,
    },
    picker: {
        height: Platform.OS === "ios" ? "auto" : 30,
        width: "50%",
        marginTop: 30,
    },
    saveButton: {
        marginTop: 20,
    },
});

const modalStyles = StyleSheet.create({
    modal: {
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        backgroundColor: highOpacityGrey,
    },
    editKidContainer: {
        position: "absolute",
        top: 40,
        width: "65%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: 20,
        padding: 40,
        alignItems: "center",
    },
    selectKidContainer: {
        height: 200,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
    },
    selectKidImage: {
        height: "100%",
        resizeMode: "contain",
    },
    active: { opacity: 1 },
    inactive: { opacity: 0.3 },
    editTextTitle: {
        fontSize: 40,
        color: blue,
        fontFamily: "Roboto_Bold",
        marginBottom: 20,
        marginRight: 8,
        marginLeft: 8,
    },
    editTextAge: {
        fontSize: 25,
        fontFamily: "Roboto_Light",
        marginLeft: 10,
        marginRight: 10,
    },
    editTextAgeInput: {
        fontSize: 30,
        fontFamily: "Roboto_Bold",
        borderBottomWidth: 2,
        borderBottomColor: darkGrey,
        textAlign: "center",
        color: blue,
    },
    alignLeft: {
        textAlign: "left",
    },
    alignRight: {
        textAlign: "right",
    },
    editAgeContainer: {
        display: "flex",
        flexDirection: "row",
        marginTop: 60,
        justifyContent: "center",
        alignItems: "center",
    },
    label: {
        textAlign: "center",
        fontSize: 20,
        color: darkGrey,
        marginTop: 10,
    },
    editButtonsContainer: {
        display: "flex",
        flexDirection: "row",
        marginTop: 40,
        justifyContent: "space-evenly",
    },
    nameInputContainer: {
        flexDirection: "row",
        display: "flex",
        marginBottom: 40,
    },
    datePicker: {
        height: Platform.OS === "web" ? 200 : 100,
    },
});
