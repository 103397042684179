import { firebaseFunctions } from "../firebase";
import { Feedback } from "./Levels";

export interface ParticipantsTeamSummary {
    teamId: string;
    participantsIds: string[];
    groupId: string;
    projectId: string;
}

interface ParticipantsTeamInfo extends ParticipantsTeamSummary {
    progress?: LevelProgress[];
}

export interface LevelProgress {
    levelId: string;
    resultSent?: LevelSubmission;
    feedback?: Feedback;
}

interface LevelSubmission {
    message: string;
    attachment?: boolean;
    attachmentBase64?: string;
    attachmentUrl?: string;
    timestamp: number;
}

export class ParticipantsTeam {
    constructor() {}
    static async postNewParticipantsTeam(team: ParticipantsTeamInfo) {
        const response = await firebaseFunctions.httpsCallable(
            "participants-postNewParticipantsTeam"
        )({
            team: team,
        });
        return response.data;
    }

    static async getKidTeams(
        kidId: string
    ): Promise<ParticipantsTeamSummary[]> {
        const response = await firebaseFunctions.httpsCallable(
            "participants-getKidGroupIds"
        )({ kidId: kidId });
        return response.data;
    }

    static async sendSubmission(
        teamId: string,
        levelId: string,
        base64: string
    ) {
        const response = await firebaseFunctions.httpsCallable(
            "participants-sendSubmission"
        )({ teamId: teamId, levelId: levelId, base64: base64 });
        return response.data;
    }
}
