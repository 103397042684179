/** Given a birth date in a string with format (YYYY/MM/DD), returns the age today */
export function getAge(birth: number) {
    var today = new Date(Date.now());
    var birthDate = new Date(birth);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
