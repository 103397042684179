import { useNavigation } from "@react-navigation/core";
import React, { useContext, useEffect, useState } from "react";
import {
    Text,
    View,
    StyleSheet,
    Image,
    Dimensions,
    FlatList,
    ListRenderItemInfo,
    TouchableOpacity,
    ScrollView,
} from "react-native";
/* import { ScrollView } from "react-native-gesture-handler";
 */ import { Chip, Divider } from "react-native-paper";
import { blue, green, red, white, yellow } from "../../Colors";
import { Product, StoreMainPage } from "../../Service/Store";
import { VerticalPage } from "./VerticalPage";
import { Loading } from "../UI/Loading";
import { StoreContext } from "../../provider/Store";

const { width, height, scale } = Dimensions.get("window");

/* const exampleProductPreview: Omit<ProductPreviewProps, "onProjectClick"> = {
    imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/Projects%2FEspai%20Exterior%2Fspace-background-image.jpg?alt=media&token=15af7b3c-4fb1-48df-8592-22502ad37706",
    title: "Extraescolar 2021-22",
    subtitle: " Des de setembre fins a juny",
    price: 28,
    chips: ["Totes les edats", "Totes els idiomes"],
    size: "big",
};
 */ export const StorePage = () => {
    const navigation = useNavigation();

    const [storeMainPage, setStoreMainPage] = useState<StoreMainPage>();
    const [loading, setLoading] = useState(true);
    const [fatalError, setFatalError] = useState("");

    const storeContext = useContext(StoreContext);

    useEffect(() => {
        loadProducts().finally(() => setLoading(false));
    }, [storeContext]);

    async function loadProducts() {
        if (storeContext!.storeMainPage === null) {
            setFatalError("Could not load the products");
            return;
        }

        if (storeContext!.storeMainPage === undefined) {
            setLoading(true);
            await storeContext!.updateStoreMainPage();
            return;
        }

        setStoreMainPage(storeContext!.storeMainPage);
        return;
    }

    const onProjectClick = (projectId: string) =>
        navigation.navigate("ProductDetails", { projectId: projectId });

    const getProductPreviewProps = (
        product: Product,
        size: "small" | "big"
    ): ProductElementProps => {
        return {
            ...product,
            size: size,
            onProjectClick: onProjectClick,
        };
    };

    if (loading) return <Loading fullScreen />;
    if (fatalError) return <Text>{fatalError}</Text>;
    return (
        <VerticalPage>
            <ScrollView>
                <View style={styles.container}>
                    <Text style={styles.headerText}>Botiga de projectes</Text>
                    <Divider
                        style={{
                            borderColor: blue,
                            borderWidth: 2,
                            width: "100%",
                        }}
                    />
                    <ProductElement
                        {...getProductPreviewProps(
                            storeMainPage!.starred,
                            "big"
                        )}
                    />
                </View>

                <StoreBody
                    products={
                        storeMainPage?.products.map((product) => {
                            return getProductPreviewProps(product, "small");
                        }) ?? []
                    }
                />
            </ScrollView>
        </VerticalPage>
    );
};

interface ProductElementProps extends Product {
    size: "big" | "small";
    onProjectClick: (projectId: string) => void;
}

const ProductElement = (props: ProductElementProps) => {
    function getChipColors(ind: number) {
        switch (ind % 4) {
            case 0:
                return green;
            case 1:
                return blue;
            case 2:
                return red;
            case 3:
                return yellow;
        }
    }
    return (
        <View
            style={[
                styles.shadow,
                projStyles.container,
                props.size === "big"
                    ? bProjStyles.container
                    : sProjStyles.container,
            ]}
        >
            <TouchableOpacity
                onPress={
                    props.onProjectClick
                        ? () => props.onProjectClick!(props.projectId)
                        : undefined
                }
            >
                <View
                    style={[
                        projStyles.imageContainer,
                        props.size === "big"
                            ? bProjStyles.imageContainer
                            : sProjStyles.imageContainer,
                    ]}
                >
                    <View
                        style={[
                            projStyles.chipsContainer,
                            props.size === "big"
                                ? bProjStyles.chipsContainer
                                : sProjStyles.chipsContainer,
                        ]}
                    >
                        {props.chips?.map((chip, ind) => (
                            <Chip
                                key={ind}
                                style={[
                                    bProjStyles.chip,
                                    { backgroundColor: getChipColors(ind) },
                                ]}
                                textStyle={[
                                    projStyles.chipText,
                                    props.size === "big"
                                        ? bProjStyles.chipText
                                        : sProjStyles.chipText,
                                ]}
                            >
                                {chip}
                            </Chip>
                        ))}
                    </View>

                    <Image
                        style={[
                            projStyles.image,
                            props.size === "big"
                                ? bProjStyles.image
                                : sProjStyles.image,
                        ]}
                        source={{ uri: props.imageUrl }}
                    />
                </View>
                <View
                    style={[
                        projStyles.textContainer,
                        props.size === "big"
                            ? bProjStyles.textContainer
                            : sProjStyles.textContainer,
                    ]}
                >
                    <View>
                        <Text
                            style={[
                                projStyles.title,
                                props.size === "big"
                                    ? bProjStyles.title
                                    : sProjStyles.title,
                            ]}
                        >
                            {props.title}
                        </Text>
                        <Text
                            style={[
                                projStyles.subtitle,
                                props.size === "big"
                                    ? bProjStyles.subtitle
                                    : sProjStyles.subtitle,
                            ]}
                        >
                            {props.subtitle}
                        </Text>
                    </View>
                    <View>
                        <Text
                            style={[
                                projStyles.label,
                                props.size === "big"
                                    ? bProjStyles.label
                                    : sProjStyles.label,
                            ]}
                        >
                            Des de...
                        </Text>
                        <Text
                            style={[
                                projStyles.price,
                                props.size === "big"
                                    ? bProjStyles.price
                                    : sProjStyles.price,
                            ]}
                        >
                            {props.price}€/mes
                        </Text>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    );
};

interface StoreProps {
    products: ProductElementProps[];
}
const StoreBody = (props: StoreProps) => {
    let mixedProjects: ProductElementProps[] = [],
        roboticsProjects: ProductElementProps[] = [],
        programmingProjects: ProductElementProps[] = [];

    props.products.forEach((product) => {
        if (product.programming && product.robotics)
            mixedProjects.push(product);
        else if (product.robotics) roboticsProjects.push(product);
        else if (product.programming) programmingProjects.push(product);
    });

    return (
        <View
            style={[
                styles.container,
                { marginTop: 50, paddingLeft: "5%", paddingRight: "5%" },
            ]}
        >
            {mixedProjects.length > 0 ? (
                <BodySection
                    products={mixedProjects}
                    title="Projectes mixtos"
                />
            ) : undefined}
            {roboticsProjects.length > 0 ? (
                <BodySection
                    products={roboticsProjects}
                    title="Projectes exclusius de robòtica"
                />
            ) : undefined}
            {programmingProjects.length > 0 ? (
                <BodySection
                    products={programmingProjects}
                    title="Projectes exclusius de programació"
                />
            ) : undefined}
        </View>
    );
};

const BodySection = (props: {
    products: ProductElementProps[];
    title: string;
}) => {
    return (
        <View style={{ width: "100%", marginBottom: 30 }}>
            <Text style={styles.sectionTitle}>{props.title}</Text>
            <Divider
                style={{
                    width: "50%",
                    alignSelf: "flex-start",
                    height: 2,
                    borderColor: red,
                }}
            />
            <View style={{ width: "100%" }}>
                <ProjectsArray projects={props.products} />
            </View>
        </View>
    );
};
interface ProjectsArrayProps {
    projects: ProductElementProps[];
}
const ProjectsArray = (props: ProjectsArrayProps) => {
    const renderItem = (item: ListRenderItemInfo<ProductElementProps>) => (
        <View style={{ width: "48%" }} key={item.index}>
            <ProductElement {...item.item} size="small" />
        </View>
    );
    return (
        <View style={{ maxHeight: 500 }}>
            <FlatList
                data={props.projects}
                renderItem={renderItem}
                numColumns={2}
                scrollEnabled
                columnWrapperStyle={{ justifyContent: "space-between" }}
                showsVerticalScrollIndicator
                keyExtractor={(_, index) => index.toString()}
            />
        </View>
    );
};
const bProjStyles = StyleSheet.create({
    container: {
        marginTop: 30,
        borderRadius: 40,
    },
    imageContainer: {
        height: 350,
    },
    image: {
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40,
    },
    textContainer: {
        padding: 30,
    },
    title: {
        fontSize: 25,
        marginBottom: 10,
    },
    subtitle: {
        fontSize: 20,
    },
    label: {
        fontSize: 16,
        marginBottom: 5,
    },
    price: {
        fontSize: 25,
    },
    chipsContainer: {
        padding: 30,
    },
    chip: {
        marginBottom: 10,
    },
    chipText: {
        fontSize: 18,
    },
});

const projStyles = StyleSheet.create({
    container: {
        backgroundColor: white,
        width: "100%",
        marginBottom: 30,
    },
    imageContainer: {
        position: "relative",
        width: "100%",
    },
    image: {
        height: "100%",
        width: "100%",
        resizeMode: "cover",
    },
    textContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 30,
    },
    title: {
        fontFamily: "Roboto_Bold",
    },
    subtitle: {
        fontFamily: "Roboto_Light",
    },
    label: {
        fontFamily: "Roboto_Light",
        textAlign: "center",
    },
    price: {
        fontFamily: "Roboto",
        fontSize: 25,
    },
    chipsContainer: {
        zIndex: 10,
        position: "absolute",
        top: 0,
    },
    chip: {
        color: white,
    },
    chipText: {
        color: white,
        fontFamily: "Roboto_Bold",
    },
});

const sProjStyles = StyleSheet.create({
    container: {
        marginTop: 30,
        borderRadius: 20,
    },
    imageContainer: {
        height: 200,
    },
    image: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
    },
    textContainer: {
        padding: 20,
    },
    title: {
        fontSize: 18,
        marginBottom: 5,
    },
    subtitle: {
        fontSize: 15,
    },
    label: {
        fontSize: 13,
        marginBottom: 5,
    },
    price: {
        fontSize: 18,
    },
    chipsContainer: {
        padding: 20,
    },
    chip: {
        marginBottom: 5,
    },
    chipText: {
        fontSize: 14,
    },
});

const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingLeft: "10%",
        paddingRight: "10%",
    },
    headerText: {
        fontFamily: "Roboto_Medium",
        fontSize: 35,
        textAlign: "center",
        marginTop: 40,
        marginBottom: 20,
    },
    sectionTitle: {
        fontFamily: "Roboto_Bold",
        fontSize: 30,
        textAlign: "left",
        marginRight: "auto",
        alignSelf: "flex-start",
        marginBottom: 10,
    },
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,

        elevation: 4,
    },
});
