import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { StoreProvider } from "../../provider/Store";
import { ProductDetailsPage } from "./ProductDetails";
import { StorePage } from "./StorePage";
import { StoreSuccessPage } from "./StoreSuccessPage";

const StoreStack = createStackNavigator();
export const StoreZone = () => {
    return (
        <StoreProvider>
            <StoreStack.Navigator screenOptions={{ headerShown: false }}>
                <StoreStack.Screen
                    name="StoreDashboard"
                    component={StorePage}
                />
                <StoreStack.Screen
                    name="ProductDetails"
                    component={ProductDetailsPage}
                />
                <StoreStack.Screen
                    name="StoreSuccess"
                    component={StoreSuccessPage}
                />
            </StoreStack.Navigator>
        </StoreProvider>
    );
};
