import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import {
    ImageBackground,
    Text,
    View,
    StyleSheet,
    Dimensions,
    Image,
    TouchableOpacity,
} from "react-native";
import { KidInfo } from "../../Service/User";
import { PublicProjectInformation } from "../../Service/Project";
import { ProjectHeader } from "./GeneralComponents/ProjectHeader";
import { Stars } from "../UI/UI";
import { ProjectContext } from "../../provider/Project";
import { Loading } from "../UI/Loading";
import { ChildrenContext } from "../../provider/Children";
import { PublicLevel } from "../../Service/Levels";
import { ParticipantsTeamSummary } from "../../Service/ParticipantsTeam";

const { height, width } = Dimensions.get("window");

type LevelDetailsRouteList = {
    Product: {
        teamSummary: ParticipantsTeamSummary;
    };
};

export const LevelSelection = () => {
    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState<PublicProjectInformation>();
    const [fatalError, setFatalError] = useState("");
    const projectContext = useContext(ProjectContext);
    const route = useRoute<RouteProp<LevelDetailsRouteList, "Product">>();
    const teamSummary = useRef<ParticipantsTeamSummary>();

    useEffect(() => {
        teamSummary.current = route.params.teamSummary;
        const project = projectContext?.projects?.find(
            (val) =>
                val.id === teamSummary.current!.projectId &&
                val.groupId === teamSummary.current!.groupId &&
                val.teamId === teamSummary.current!.teamId
        );
        if (!project) {
            setFatalError("Could not get the project");
            return;
        }
        if (project?.levels === undefined) {
            projectContext!.getLevels(teamSummary.current!);
            return;
        }
        if (project?.levels === null) {
            setFatalError("Could not get the levels of the project");
            setLoading(false);
            return;
        }
        setProject(project);
        setLoading(false);
    }, [route, projectContext]);

    if (loading) return <Loading background fullScreen />;
    if (fatalError) return <Text>{fatalError}</Text>;

    return (
        <View style={{ flex: 1 }}>
            <ImageBackground
                source={{ uri: project!.urlImages?.backgroundImage }}
                style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Screen project={project!} teamSummary={teamSummary.current!} />
            </ImageBackground>
        </View>
    );
};

const Screen = (props: {
    project: PublicProjectInformation;
    teamSummary: ParticipantsTeamSummary;
}) => {
    const [kid, setKid] = useState<KidInfo>();
    const navigation = useNavigation();
    const childrenContext = useContext(ChildrenContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let contextKid = childrenContext?.children?.find(
            (kid) => kid.kidId === childrenContext.currentKidId
        );
        setKid(contextKid);
        setLoading(false);
    }, [childrenContext]);

    function goBack() {
        navigation.goBack();
    }

    if (loading) return <Loading />;
    return (
        <View style={{ flex: 1, width: "100%" }}>
            <View style={{ flex: 18 }}>
                <ProjectHeader
                    players={kid ? [kid] : []}
                    leftButton={{
                        button: {
                            icon: require("../../../assets/icons/left-arrow.png"),
                            onPressFunc: goBack,
                        },
                    }}
                    stars={{ number: 30 }}
                    headerHeight={(height * 18) / (82 + 18)}
                />
            </View>
            <View style={{ flex: 82, display: "flex" }}>
                <LevelsGrid
                    project={props.project}
                    teamSummary={props.teamSummary}
                />
            </View>
        </View>
    );
};

const LevelsGrid = (props: {
    project: PublicProjectInformation;
    teamSummary: ParticipantsTeamSummary;
}) => {
    const sortLevels = (a: PublicLevel, b: PublicLevel) => {
        if (a.number < b.number) return -1;
        if (a.number === b.number) return 0;
        return 1;
    };
    return (
        <View
            style={{
                flex: 1,
                marginLeft: width * 0.08,
                marginRight: width * 0.08,
                paddingTop: 100,
                paddingBottom: 100,
                display: "flex",
                alignContent: "flex-start",
                justifyContent: "center",
                flexDirection: "row",
                flexWrap: "wrap",
            }}
        >
            {Object.values(props.project.levels!)
                .sort(sortLevels)
                .map((val, ind) => (
                    <LevelComponent
                        level={val}
                        key={ind}
                        teamSummary={props.teamSummary}
                    />
                ))}
        </View>
    );
};

const LevelComponent = (props: {
    level: PublicLevel;
    teamSummary: ParticipantsTeamSummary;
}) => {
    const navigation = useNavigation();

    function loadLevel() {
        if (!props.level.unlocked) return;
        else
            navigation.navigate("LevelPage", {
                levelId: props.level.id,
                teamSummary: props.teamSummary,
            });
    }

    function loadFeedback() {
        if (!props.level.unlocked || !props.level.levelProgress?.feedback)
            return;
        else navigation.navigate("FeedbackPage", { level: props.level });
    }

    return (
        <View style={levelStyles.container}>
            <TouchableOpacity
                style={levelStyles.whiteContainer}
                onPress={loadLevel}
            >
                {!props.level.unlocked ? (
                    <Lock />
                ) : (
                    <Text style={levelStyles.levelNumberText}>
                        {props.level.number}
                    </Text>
                )}
            </TouchableOpacity>
            <TouchableOpacity
                style={levelStyles.starsContainer}
                onPress={loadFeedback}
            >
                <Stars
                    yellowStars={props.level.levelProgress?.feedback?.stars}
                    starsNumber={3}
                />
            </TouchableOpacity>
        </View>
    );
};

const Lock = () => {
    return (
        <View style={{ flex: 1, margin: 20, width: "100%", height: "100%" }}>
            <Image
                source={require("../../../assets/icons/padlock.png")}
                style={{
                    flex: 1,
                    resizeMode: "contain",
                    width: "100%",
                }}
            ></Image>
        </View>
    );
};

const levelStyles = StyleSheet.create({
    container: {
        height: height * 0.19,
        width: height * 0.15,
        margin: width * 0.03,
        backgroundColor: "rgba(85,85,85,1)",
        borderRadius: 20,
        borderColor: "rgba(112,112,112,1)",
        borderWidth: 1,
        display: "flex",
        alignItems: "center",
    },
    whiteContainer: {
        width: "100%",
        height: "77%",
        backgroundColor: "white",
        borderRadius: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    levelNumberText: {
        fontFamily: "Roboto_Bold",
        fontSize: 91,
        color: "rgba(85,85,85,1)",
    },
    starsContainer: {
        width: "80%",
        height: "23%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
});
const LoadingText = () => {
    return (
        <View
            style={{
                height: "100%",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
            }}
        >
            <Text style={{ fontSize: 50 }}>{"Loading..."}</Text>
        </View>
    );
};
