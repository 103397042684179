import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React from "react";
import {
    StyleSheet,
    View,
    Text,
    Dimensions,
    ScrollView,
    Image,
} from "react-native";
import { Feedback, PublicLevel } from "../../Service/Levels";
import {} from "../../Service/Project";
import { TextAnimator } from "../UI/TextAnimator";
import { FullScreenBackground, Stars, UIButton } from "../UI/UI";

const { height, width } = Dimensions.get("window");

type FeedbackRouteList = {
    Feedback: {
        level: PublicLevel;
    };
};

let level: PublicLevel;
const headerFlex = 22;
const bodyFlex = 78;

export const FeedbackPage = () => {
    if (!level) {
        level =
            useRoute<RouteProp<FeedbackRouteList, "Feedback">>().params.level;
        if (!level) return <View />;
    }

    return (
        <View style={feedbackPageStyles.container}>
            <FullScreenBackground>
                <View
                    key="headerContainer"
                    style={feedbackPageStyles.headerContainer}
                >
                    <FeedbackHeader level={level} />
                </View>
                <View
                    key="bodyContainer"
                    style={feedbackPageStyles.bodyContainer}
                >
                    <FeedbackBody
                        feedback={level.levelProgress?.feedback!}
                        height={(height * bodyFlex) / (bodyFlex + headerFlex)}
                    />
                </View>
            </FullScreenBackground>
        </View>
    );
};

const feedbackPageStyles = StyleSheet.create({
    container: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    headerContainer: {
        flex: headerFlex,
    },
    bodyContainer: {
        flex: bodyFlex,
    },
});

const FeedbackBody = (props: { feedback: Feedback; height: number }) => {
    return (
        <View style={{ height: props.height }}>
            <ScrollView>
                <View style={feedbackBodyStyles.container}>
                    <View style={feedbackBodyStyles.instructorImageContainer}>
                        <Image
                            source={
                                props.feedback.instructorImage
                                    ? {
                                          uri: props.feedback.instructorImage,
                                      }
                                    : require("../../../assets/icons/instructor.png")
                            }
                            style={feedbackBodyStyles.instructorImage}
                        />
                    </View>
                    <View style={feedbackBodyStyles.messageBoxContainer}>
                        <TextAnimator
                            textStyle={feedbackBodyStyles.messageText}
                        >
                            {props.feedback.content.type === "text"
                                ? props.feedback.content.text
                                : ""}
                        </TextAnimator>
                    </View>
                </View>
            </ScrollView>
        </View>
    );
};

const feedbackBodyStyles = StyleSheet.create({
    container: {
        width: "100%",
        alignItems: "center",
    },

    instructorImageContainer: {
        height: height * 0.2,
        width: height * 0.2,
        borderRadius: 500000,
        backgroundColor: "white",
        zIndex: 1,
    },

    instructorImage: {
        flex: 1,
        resizeMode: "cover",
        borderRadius: 50000,
        width: "100%",
        height: "100%",
    },

    messageBoxContainer: {
        top: -height * 0.2 * 0.5,
        width: "73%",
        backgroundColor: "rgba(15,15,79,0.6)",
        borderRadius: 50,
        padding: 50,
        paddingTop: 100,
    },
    messageText: {
        fontFamily: "Roboto_Bold",
        fontSize: 25,
        color: "white",
        textAlign: "justify",
    },
});

const FeedbackHeader = (props: { level: PublicLevel }) => {
    const navigation = useNavigation();
    return (
        <View style={feedbackHeaderStyles.container}>
            <View style={feedbackHeaderStyles.backButtonContainer}>
                <UIButton
                    image={require("../../../assets/icons/left-arrow.png")}
                    onPress={() => navigation.goBack()}
                />
            </View>
            <View style={feedbackHeaderStyles.starsContainer}>
                <Stars
                    starsNumber={3}
                    yellowStars={props.level.levelProgress?.feedback?.stars}
                />
            </View>
            <View style={feedbackHeaderStyles.levelnumberContainer}>
                <Text style={feedbackHeaderStyles.levelNumberText}>
                    {props.level.number}
                </Text>
            </View>
        </View>
    );
};

const feedbackHeaderStyles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    backButtonContainer: {
        position: "absolute",
        left: 30,
        width: height / 8,
        height: height / 8,
    },
    levelnumberContainer: {
        width: height / 8,
        height: height / 7,
        backgroundColor: "white",
        position: "absolute",
        right: 30,
        borderRadius: 20,
        alignItems: "center",
        justifyContent: "center",
    },
    starsContainer: {
        width: height / 3,
        height: height / 8,
    },
    levelNumberText: {
        fontFamily: "Roboto_Bold",
        fontSize: 91,
        color: "rgba(85,85,85,1)",
    },
});
