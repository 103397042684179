import { useNavigation } from "@react-navigation/core";
import React, { useContext, useState } from "react";
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    Platform,
} from "react-native";
import { AuthContext } from "../../provider/Auth";
import { blue, darkBlue, white } from "../../Colors";
import { FullScreenBackground } from "../UI/UI";
import { ColorButton } from "../UI/ColorButton";
import * as Google from "expo-auth-session/providers/google";
import { useEffect } from "react";
import firebase from "firebase/app";
import { logInAsync } from "expo-google-app-auth";
import { ActivityIndicator } from "react-native-paper";

export const Welcome = () => {
    const navigation = useNavigation();
    const [loading, setLoading] = useState(false);
    const navigateToTour = () => navigation.navigate("Tour");

    const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
        expoClientId:
            "648663652774-j788etjek7ipjmgvu4v5nbrld1d2gh5o.apps.googleusercontent.com",
        androidClientId:
            "648663652774-u7sl2hdjflq8l5s6afr6schpsp61ci2c.apps.googleusercontent.com",
        webClientId:
            "648663652774-40g3706tiu52gceq3metgg42pcakq0fc.apps.googleusercontent.com",
        iosClientId:
            "648663652774-o8bv45vor12brocdfmeg28l0gi27nce8.apps.googleusercontent.com",
        selectAccount: true,
    });
    const authContext = useContext(AuthContext);
    useEffect(() => {
        if (response?.type === "success") {
            const { id_token } = response.params;
            setLoading(true);

            const credential =
                firebase.auth.GoogleAuthProvider.credential(id_token);

            firebase.auth().signInWithCredential(credential);
        }
    }, [response]);

    useEffect(() => {
        if (authContext?.currentUser && !authContext.userInfo?.userId)
            navigation.navigate("AccountFound");
    }, [authContext]);

    /** Android is bugeed and does not work with the common method */
    const temporaryPromptAsyncFix = () => {
        /*         if (Platform.OS === "android") {
            androidLogin();
            return;
        }
 */ promptAsync();
    };

    const androidLogin = () => {
        logInAsync({
            androidStandaloneAppClientId:
                "648663652774-u7sl2hdjflq8l5s6afr6schpsp61ci2c.apps.googleusercontent.com",
            behavior: "web",
            clientId:
                "648663652774-u7sl2hdjflq8l5s6afr6schpsp61ci2c.apps.googleusercontent.com",
        }).then((res) => {
            if (res.type === "success") {
                const credential = firebase.auth.GoogleAuthProvider.credential(
                    res.idToken
                );
                setLoading(true);
                firebase.auth().signInWithCredential(credential);
            }
        });
    };

    return (
        <FullScreenBackground
            source={require("../../../assets/backgrounds/children.jpg")}
        >
            <View style={styles.container}>
                <Text style={styles.header}>Benvingut a Roboclick!</Text>
                <Text style={styles.subtitle}>
                    La millor manera d'aprendre robòtica...
                </Text>
                <Text style={styles.subtitle}>des de casa!</Text>
                {loading ? <ActivityIndicator color={white} /> : undefined}

                <ColorButton
                    text="Aprèn amb nosaltres"
                    onPress={() => navigateToTour()}
                    bColor={blue}
                    tColor="#FFFFFF"
                    border={darkBlue}
                    style={styles.colorButton}
                />
                <View style={styles.loginTextContainer}>
                    <TouchableOpacity onPress={() => temporaryPromptAsyncFix()}>
                        <Text style={styles.loginText}>
                            O, si ja estàs registrat, inicia sessió.
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </FullScreenBackground>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        display: "flex",
        alignItems: "center",
    },
    header: {
        fontFamily: "Roboto_Bold",
        color: "#FFFFFF",
        fontSize: 70,
        marginTop: "auto",
        marginBottom: 20,
    },
    colorButton: {
        marginTop: 40,
        marginBottom: 15,
    },

    subtitle: {
        fontFamily: "Roboto_Bold",
        color: "#FFFFFF",
        fontSize: 30,
    },
    loginTextContainer: {
        marginBottom: 50,
    },
    loginText: {
        color: "#FFFFFF",
        fontSize: 20,
    },
});
