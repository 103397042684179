import { firebaseFunctions } from "../firebase";

export interface NewInformation {
    newId: string;
    title: string;
    body: string;
    imageUrl: string;
    button?: {
        url: string;
        text: string;
        color?: string;
    };
}

export class News {
    constructor() {}

    static async getUserNews(userId: string): Promise<NewInformation[]> {
        const response = await firebaseFunctions.httpsCallable(
            "users-getUserNews"
        )({ userId: userId });
        return response.data as NewInformation[];
    }
}
