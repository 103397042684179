import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
    Dimensions,
    Image,
    ImageSourcePropType,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from "react-native";
import { UserInformation } from "../../Service/User";
import { FullScreenBackground, HomeButton } from "../UI/UI";

const { height, width } = Dimensions.get("window");

/** Returns a full screen view with two buttons to choose between using the app alone or in a team */
export const PlayerChoice = () => {
    const navigation = useNavigation();
    const imageWidth = width / 2.3;
    const imageHeight = height * 0.55;

    return (
        <View style={styles.screenContainer}>
            <FullScreenBackground>
                <View style={styles.header}>
                    <View style={[styles.homeButton]}>
                        <HomeButton />
                    </View>
                    <Text style={[styles.title]}>COM TREBALLES?</Text>
                </View>
                <View style={styles.content}>
                    <View style={styles.buttonsContainer}>
                        <View style={styles.optionContainer}>
                            <TouchableOpacity onPress={multiplePlayerSelected}>
                                <Option
                                    imageHeight={imageHeight}
                                    imageWidth={imageWidth}
                                    title="SOM UN EQUIP!"
                                    image={require("../../../assets/selection-team/team.jpg")}
                                    id={0}
                                />
                            </TouchableOpacity>
                        </View>
                        <View style={styles.optionContainer}>
                            <TouchableOpacity onPress={singlePlayerSelected}>
                                <Option
                                    imageHeight={imageHeight}
                                    imageWidth={imageWidth}
                                    title="AVUI TREBALLO SOL!"
                                    image={require("../../../assets/selection-team/alone.jpg")}
                                    id={1}
                                />
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </FullScreenBackground>
        </View>
    );

    function singlePlayerSelected() {
        UserInformation.multiplePlayers = false;
        navigateToLevelSelection();
    }

    function multiplePlayerSelected() {
        UserInformation.multiplePlayers = true;
        navigateToLevelSelection();
    }

    function navigateToLevelSelection() {
        navigation.navigate("LevelSelection");
    }
};

/** Only used in this component, it is used to control the props of Option
 * @param title: The name of this option
 * @param image: The image (with the require annotation) to be used
 * @param id: An id to identify each button
 */

interface OptionProps {
    imageWidth: number;
    imageHeight: number;
    title: string;
    image: ImageSourcePropType;
    id: number;
}

/** Returns a view with an image occupying 55% of the screen height and a title */
const Option = (props: OptionProps) => {
    return (
        <View>
            <View
                style={[
                    {
                        width: props.imageWidth,
                        height: props.imageHeight,
                        borderRadius: 50,
                        maxWidth: 1000,
                    },
                    styles.optionImage,
                ]}
            >
                <Image
                    source={props.image}
                    style={[
                        styles.fill,
                        {
                            resizeMode: "cover",
                            borderRadius: 50,
                        },
                    ]}
                />
            </View>
            <Text style={styles.textButton}>{props.title}</Text>
        </View>
    );
};

/**  The styles used in all the Player Choice components */
const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },

    header: {
        display: "flex",
        flexDirection: "row",
        flex: 18,
        alignItems: "flex-end",
        justifyContent: "center",
    },

    content: {
        display: "flex",
        flexDirection: "column",
        flex: 82,
        justifyContent: "center",
    },

    fill: {
        height: "100%",
        width: "100%",
    },

    homeButton: {
        position: "absolute",
        left: 30,
        width: height / 8,
        height: height / 8,
    },

    title: {
        fontSize: 70,
        textAlign: "center",
        fontFamily: "Roboto_Bold",
        color: "white",
        textShadowColor: "grey",
        textShadowOffset: { width: 0, height: 2 },
        textShadowRadius: 10,
    },

    buttonsContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },

    optionContainer: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },

    optionImage: {
        shadowColor: "black",
        shadowOffset: { width: 0, height: 10 },
        shadowOpacity: 0.5,
        shadowRadius: 15,
    },

    textButton: {
        fontFamily: "Roboto_Bold",
        fontSize: 40,
        textAlign: "center",
        color: "white",
        marginTop: 20,
        textShadowColor: "grey",
        textShadowOffset: { width: 0, height: 2 },
        textShadowRadius: 10,
    },
});
