import React, { useContext, useEffect, useState } from "react";
import { ParticipantsTeamSummary } from "src/Service/ParticipantsTeam";
import { Level } from "../Service/Levels";
import { Project, PublicProjectInformation } from "../Service/Project";
import { ChildrenContext } from "./Children";

export const ProjectContext = React.createContext<ProjectContextObject>(null);

type ProjectContextObject = {
    projects: PublicProjectInformation[] | null;
    resetProjects: () => Promise<PublicProjectInformation[] | null>;
    getLevels: (teamSummary: ParticipantsTeamSummary) => void;
    getLevelSteps: (
        teamSummary: ParticipantsTeamSummary,
        levelId: string
    ) => void;
} | null;

export const ProjectProvider = (props: { children: any }) => {
    const [currentContext, setCurrentContext] =
        useState<ProjectContextObject>(null);

    const childrenContext = useContext(ChildrenContext);

    useEffect(() => {
        getProjects()
            .then((res) => {
                setCurrentContext({
                    projects: res,
                    resetProjects: resetProjects,
                    getLevels: getLevels,
                    getLevelSteps: getLevelSteps,
                });
            })
            .catch(() => {});
    }, [childrenContext]);

    async function getProjects(): Promise<PublicProjectInformation[] | null> {
        let currentKid = childrenContext?.children?.find(
            (kid) => kid.kidId === childrenContext!.currentKidId
        );

        if (!currentKid) return null;

        if (!currentKid.teams?.onGoing) return [];
        return await Project.getProjectsOfGroups(currentKid!.teams!.onGoing!);
    }

    async function resetProjects(): Promise<PublicProjectInformation[] | null> {
        const projects = await getProjects();
        setCurrentContext((currentContext) => {
            if (currentContext !== null)
                return { ...currentContext, projects: projects };
            else
                return {
                    projects: projects,
                    resetProjects: resetProjects,
                    getLevels: getLevels,
                    getLevelSteps: getLevelSteps,
                };
        });
        return projects;
    }

    async function getLevels(teamSummary: ParticipantsTeamSummary) {
        const { projectId, groupId, teamId } = teamSummary;
        const levels = await Level.getLevelsOfProjectGroupAndTeam(teamSummary);

        setCurrentContext((context) => {
            const copiedProjects = [...context!.projects!];
            const modifiedProjectInd = copiedProjects.findIndex(
                (proj) =>
                    proj.id === projectId &&
                    proj.groupId === groupId &&
                    proj.teamId === teamId
            );
            copiedProjects[modifiedProjectInd].levels = levels;

            return { ...context!, projects: copiedProjects };
        });
    }

    async function getLevelSteps(
        teamSummary: ParticipantsTeamSummary,
        levelId: string
    ) {
        const { projectId, groupId, teamId } = teamSummary;
        const levelSteps = await Level.getLevelSteps(
            projectId,
            levelId,
            teamId
        );

        setCurrentContext((context) => {
            const copiedProjects = [...context!.projects!];
            const modifiedProjectInd = copiedProjects.findIndex(
                (proj) =>
                    proj.id === projectId &&
                    proj.groupId === groupId &&
                    proj.teamId === teamId
            );
            copiedProjects[modifiedProjectInd].levels![levelId].steps =
                levelSteps;
            return { ...context!, projects: copiedProjects };
        });
    }

    return (
        <ProjectContext.Provider value={currentContext}>
            {props.children}
        </ProjectContext.Provider>
    );
};
