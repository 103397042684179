import React from "react";
import {
    View,
    TouchableOpacity,
    Text,
    StyleSheet,
    StyleProp,
    ViewStyle,
    TextStyle,
} from "react-native";

export const ColorButton = (props: {
    bColor: string;
    tColor: string;
    text: string;
    onPress: () => void;
    border?: string;
    style?: StyleProp<ViewStyle>;
    centerText?: boolean;
    disabled?: boolean;
    textStyle?: StyleProp<TextStyle>;
}) => {
    const {
        bColor,
        tColor,
        text,
        onPress,
        style,
        border,
        centerText,
        disabled,
        textStyle,
    } = props;
    return (
        <View
            style={[
                styles.colorButton,
                { backgroundColor: bColor, borderColor: border },
                style,
            ]}
        >
            <TouchableOpacity onPress={() => onPress()} disabled={disabled}>
                <Text
                    style={[
                        styles.colorButtonText,
                        {
                            color: tColor,
                            textAlign: centerText ? "center" : "auto",
                        },
                        textStyle,
                    ]}
                >
                    {text}
                </Text>
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    colorButton: {
        borderRadius: 30,
        padding: 10,
        paddingLeft: 15,
        paddingRight: 15,
    },
    colorButtonText: {
        fontSize: 20,
    },
});
