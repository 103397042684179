import { RouteProp, useRoute } from "@react-navigation/native";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Dimensions,
    View,
    FlatList,
    ListRenderItemInfo,
    ViewToken,
} from "react-native";
import { FullScreenBackground } from "../UI/UI";
import { Instructions } from "./LevelComponents/Instructions";

import { SimpleText } from "./LevelComponents/SimpleText";
import { SimpleVideo } from "./LevelComponents/SimpleVideo";
import { ProjectHeader } from "./GeneralComponents/ProjectHeader";
import { PublicLevelStep, PublicLevel } from "../../Service/Levels";
import { ParticipantsTeamSummary } from "../../Service/ParticipantsTeam";
import { ProjectContext } from "../../provider/Project";
import { Loading } from "../UI/Loading";
import { ChildrenContext } from "../../provider/Children";
import { FileUpload } from "./LevelComponents/FileUpload";

const { height, width } = Dimensions.get("window");
let flatList: FlatList;
const setSwiperReference = (element: FlatList) => {
    flatList = element;
};

let scrollViewLength = 0;

type LevelRouteList = {
    Level: {
        levelId: string;
        teamSummary: ParticipantsTeamSummary;
    };
};
export const LevelPage = () => {
    const route = useRoute<RouteProp<LevelRouteList, "Level">>();

    const projectContext = useContext(ProjectContext);
    const [fatalError, setFatalError] = useState("");
    const [loading, setLoading] = useState(true);
    const [level, setLevel] = useState<PublicLevel>();

    const [slideIndex, setSlideIndex] = useState(0);

    const teamSummary = route.params.teamSummary;
    const levelId = route.params.levelId;

    useEffect(() => {
        const { projectId, groupId, teamId } = teamSummary;
        const project = projectContext?.projects?.find(
            (proj) =>
                proj.groupId === groupId &&
                proj.id === projectId &&
                proj.teamId === teamId
        );
        if (!project) {
            setFatalError("We can't get the project");
            setLoading(false);
            return;
        }
        if (!project.levels?.[levelId]) {
            setFatalError("We can't get the level");
            setLoading(false);
            return;
        }
        if (project!.levels![levelId].steps === null) {
            setFatalError("We can't get the level");
            setLoading(false);
            return;
        }
        if (project!.levels![levelId].steps === undefined) {
            projectContext!.getLevelSteps(teamSummary, levelId);
            return;
        }
        setLevel(project!.levels![levelId]);
        setLoading(false);
    }, [projectContext, route]);

    if (loading) return <Loading background fullScreen />;

    let notFirstSlide = slideIndex !== 0;
    let notLastSlide = !flatList || slideIndex + 1 < scrollViewLength;

    scrollViewLength = level!.steps!.length;

    if (flatList) {
        flatList.scrollToIndex({
            index: slideIndex,
            animated: true,
        });
    }

    function goBack() {
        if (slideIndex !== 0) updateSlideIndex(-1);
    }

    function goNext() {
        if (slideIndex + 1 < scrollViewLength) updateSlideIndex(1);
    }

    function updateSlideIndex(addition: number) {
        setSlideIndex(slideIndex + addition);
    }

    return (
        <FullScreenBackground>
            <View style={{ flex: 18 }}>
                <LevelPageHeader
                    goBack={goBack}
                    goNext={goNext}
                    notFirstSlide={notFirstSlide}
                    notLastSlide={notLastSlide}
                />
            </View>
            <View
                style={{
                    flex: 82,
                    display: "flex",
                }}
            >
                <LevelPageBody level={level!} teamId={teamSummary.teamId} />
            </View>
        </FullScreenBackground>
    );
};

const LevelPageHeader = (props: {
    notFirstSlide: boolean;
    notLastSlide: boolean;
    goBack: Function;
    goNext: Function;
}) => {
    const childrenContext = useContext(ChildrenContext);
    const kid = childrenContext!.children!.find(
        (kid) => kid.kidId === childrenContext!.currentKidId
    );
    return (
        <ProjectHeader
            players={kid ? [kid] : []}
            leftButton={
                props.notFirstSlide
                    ? {
                          button: {
                              icon: require("../../../assets/icons/left-arrow.png"),
                              onPressFunc: props.goBack,
                          },
                      }
                    : undefined
            }
            headerHeight={(height * 18) / (82 + 18)}
            rightButton={
                props.notLastSlide
                    ? {
                          button: {
                              icon: require("../../../assets/icons/right-arrow.png"),
                              onPressFunc: props.goNext,
                          },
                      }
                    : undefined
            }
        ></ProjectHeader>
    );
};

const LevelPageBody = (props: { level: PublicLevel; teamId: string }) => {
    const [shownItems, setShownItems] = useState<ViewToken[]>();
    const viewConfigRef = useRef({ itemVisiblePercentThreshold: 95 });
    const onViewableItemsChanged = useRef(
        (info: { viewableItems: ViewToken[]; changed: ViewToken[] }) =>
            setShownItems(info.viewableItems)
    );

    function getItemVisibility(index: number): boolean {
        let exists = shownItems?.findIndex((item) => item.index === index);
        if (exists !== undefined && exists >= 0) return true;
        return false;
    }

    return (
        <FlatList
            horizontal
            scrollEnabled={false}
            initialNumToRender={2}
            maxToRenderPerBatch={3}
            keyExtractor={(item, index) => index.toString()}
            pagingEnabled
            data={props.level.steps}
            renderItem={(item) => (
                <Item
                    step={item.item}
                    visible={getItemVisibility(item.index)}
                    levelId={props.level.id}
                    teamId={props.teamId}
                />
            )}
            ref={(element: FlatList) => setSwiperReference(element)}
            nestedScrollEnabled
            getItemLayout={(data, index) => ({
                length: width,
                offset: width * index,
                index,
            })}
            onViewableItemsChanged={onViewableItemsChanged.current}
            windowSize={3}
            viewabilityConfig={viewConfigRef.current}
        />
    );
};

const Item = (props: {
    step: PublicLevelStep;
    visible: boolean;
    levelId: string;
    teamId: string;
}) => {
    switch (props.step.type) {
        /*         
        case "FileDownload":
            return <FileDownload />;

        case "FileUpload":
            return <FileUpload />;
 */
        case "SimpleInstructions":
            return (
                <Instructions
                    imageGallery={props.step.data.imageGallery}
                    height={(height * 82) / 100}
                />
            );

        case "SimpleText":
            return (
                <SimpleText
                    text={props.step.data.text}
                    height={(height * 82) / 100}
                    audio={props.step.data.audioUrl}
                    image={
                        props.step.data.imageUrl
                            ? { uri: props.step.data.imageUrl }
                            : undefined
                    }
                    visible={props.visible}
                    index={props.step.id}
                />
            );

        case "SimpleVideo":
            return (
                <SimpleVideo
                    videos={props.step.data.videos}
                    height={(height * 82) / 100}
                    visible={props.visible}
                />
            );
        case "FileUpload":
            return (
                <FileUpload
                    height={(height * 82) / 100}
                    message={props.step.data.message}
                    attachmentSent={props.step.data.attachmentSent}
                    image={
                        props.step.data.imageUrl
                            ? { uri: props.step.data.imageUrl }
                            : undefined
                    }
                    levelId={props.levelId}
                    teamId={props.teamId}
                />
            );
        /*
        case "TextAndImage":
            return <TextAndImage />;

        case "TextProcessing":
            return <TextProcessing />;

        case "ImageOrVideoAttachment":
            return <CameraComponent height={(height * 82) / 100} />;
 */
        default:
            return <View />;
    }
};
