import React from "react";
import { Image, StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { shadow } from "../../../../Colors";
import { KidInfo } from "../../../../Service/User";

export interface AvatarEl {
    id: string;
    url: string;
    available: boolean;
    price?: number;
}

interface AvatarProps {
    onPress?: Function;
    player: KidInfo;
    backgroundColor?: string;
    textColor?: string;
    bold?: boolean;
    noText?: boolean;
}

export const Avatar = (props: AvatarProps) => {
    const percentage = props.noText ? 100 : 70;
    const percentageString = percentage.toString().concat("%");

    return (
        <View style={avatarSettingsStyle.avatarColumn}>
            <TouchableOpacity
                style={[
                    avatarSettingsStyle.avatarImageContainer,
                    {
                        height: percentageString,
                        width: percentageString,
                        borderRadius: 200000,
                        backgroundColor: props.backgroundColor
                            ? props.backgroundColor
                            : "white",
                    },
                ]}
                onPress={() => (props.onPress ? props.onPress() : null)}
            >
                <View style={{ height: "70%", width: "70%" }}>
                    <Image
                        source={
                            props.player?.avatar
                                ? { uri: props.player.avatar }
                                : props.player.gender === "female"
                                ? require("../../../../../assets/icons/female_kid.png")
                                : require("../../../../../assets/icons/male_kid.png")
                        }
                        style={[avatarSettingsStyle.avatarImage]}
                    />
                </View>
            </TouchableOpacity>
            {props.noText ? null : (
                <Text
                    style={[
                        avatarSettingsStyle.avatarText,
                        {
                            color: props.textColor ? props.textColor : "white",
                            fontFamily: props.bold ? "Roboto_Bold" : "Roboto",
                        },
                    ]}
                >
                    {props.player.name}
                </Text>
            )}
        </View>
    );
};

const avatarSettingsStyle = StyleSheet.create({
    avatarAndSettingsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-end",
    },
    avatarColumn: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        height: "100%",
        width: "100%",
    },
    avatarImageContainer: {
        alignItems: "center",
        justifyContent: "center",
        ...shadow,
    },
    avatarImage: {
        resizeMode: "contain",
        flex: 1,
        height: "100%",
        width: "100%",
    },
    avatarText: {
        margin: 5,
        fontSize: 20,
        textAlign: "center",
    },
});
