import { useNavigation } from "@react-navigation/core";
import React, { useContext, useEffect, useState } from "react";
import {
    Image,
    ImageSourcePropType,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
} from "react-native";
import { Loading } from "../../../UI/Loading";
import { ChildrenContext } from "../../../../provider/Children";
import { AvatarSelection } from "../../../Modals/AvatarSelection";
import { ApiService } from "../../../../Service/ApiService";
import { KidInfo } from "../../../../Service/User";
import { AvatarEl, Avatar } from "./Avatar";

/** The props of the Header component. It will be amplified in the future with all the necessary information
 * @param headerHeight The height of the header
 */
export interface MainPageHeaderProps {
    headerHeight: number;
    onSnackbarChange: (snackbar: { active: boolean; message: string }) => void;
}

/** A view with the specified height containing a button to access Settings, an Avatar linking to choosing
 * which Avatar image is preferred, the provided name of the child, a button to access Videocalls and the Parents zone
 * @see HeaderProps
 */

let avatar: AvatarEl;

export const MainPageHeader = (props: MainPageHeaderProps) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentKid, setCurrentKid] = useState<KidInfo>();

    const childrenContext = useContext(ChildrenContext);
    const navigation = useNavigation();

    useEffect(() => {
        getInformation();
    }, [childrenContext]);

    async function getInformation() {
        try {
            await Promise.all([
                getChildrenInformation(),
                getAvatarInformation(),
            ]);
        } finally {
            setLoading(false);
        }
    }

    async function getAvatarInformation() {
        avatar = await ApiService.getCurrentAvatarInformation();
    }

    function getChildrenInformation() {
        if (childrenContext?.currentKidId) {
            setCurrentKid(
                childrenContext.children?.find(
                    (kid) => kid.kidId === childrenContext.currentKidId
                )
            );
        } else navigation.navigate("ChildrenSelection");
    }

    function navigateToParentsZone() {
        navigation.navigate("ParentsZone");
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <View style={headerStyle.headerContainer}>
            <View
                style={[
                    headerStyle.leftContainer,
                    {
                        height: props.headerHeight * 0.9,
                        width: props.headerHeight * 0.9,
                    },
                ]}
            >
                {modalVisible ? (
                    <AvatarSelection
                        visible={modalVisible}
                        onClosed={() => {
                            setModalVisible(false);
                        }}
                    />
                ) : null}

                <Avatar
                    onPress={() => {
                        /*                         setModalVisible(true);
                         */
                    }}
                    player={currentKid!}
                />
            </View>
            <View style={headerStyle.progressContainer}></View>
            <View style={headerStyle.rightContainer}>
                <HeaderButton
                    image={require("../../../../../assets/icons/videocall_icon.png")}
                    text="VIDEOTRUCADA"
                    height={props.headerHeight}
                    onPress={() =>
                        props.onSnackbarChange({
                            active: true,
                            message:
                                "There is not a class taking place right now",
                        })
                    }
                ></HeaderButton>
                <HeaderButton
                    image={require("../../../../../assets/icons/grades_icon.png")}
                    text="ZONA PARES"
                    height={props.headerHeight}
                    onPress={navigateToParentsZone}
                ></HeaderButton>
            </View>
        </View>
    );
};

/** Returns a button with an image and a text
 * @param image The image (with the 'required' annotation) to be used
 * @param text The text to show below the button
 * @param height The height that the button must have (will also be used to determine the width, since it is a square)
 */
const HeaderButton = (props: {
    image: ImageSourcePropType;
    text: string;
    height: number;
    onPress?: () => void;
}) => {
    return (
        <TouchableOpacity
            onPress={props.onPress ? () => props.onPress!() : undefined}
            style={[
                {
                    height: props.height,
                    width: props.height * 1.2,
                },
                buttonStyle.buttonContainer,
            ]}
        >
            <Image
                style={[
                    buttonStyle.buttonImage,
                    {
                        height: props.height * 0.7,
                        width: props.height * 0.7,
                    },
                ]}
                source={props.image}
            />
            <Text
                adjustsFontSizeToFit
                numberOfLines={1}
                allowFontScaling
                style={[buttonStyle.buttonText]}
            >
                {props.text}
            </Text>
        </TouchableOpacity>
    );
};

const headerStyle = StyleSheet.create({
    headerContainer: {
        backgroundColor: "rgba(0,32,96,0.53)",
        flex: 1,
        flexDirection: "row",
    },
    rightContainer: {
        position: "absolute",
        right: 0,
        display: "flex",
        flexDirection: "row-reverse",
        bottom: 0,
    },
    leftContainer: {
        position: "absolute",
        left: 5,
        bottom: 0,
        display: "flex",
        flexDirection: "row",
        marginBottom: 5,
    },
    progressContainer: {},
});

const buttonStyle = StyleSheet.create({
    buttonContainer: {
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
    },
    buttonImage: {
        resizeMode: "contain",
    },
    buttonText: {
        fontSize: 19,
        textAlign: "center",
        color: "white",
        textShadowColor: "black",
        textShadowOffset: { width: 0, height: 3 },
        textShadowRadius: 6,
        fontFamily: "Roboto",
    },
});
