import { AvatarEl } from "../components/KidZone/HomeScreen/Header/Avatar";
import "firebase/storage";
import { FullProject, PublicProjectInformation } from "./Project";
import { firebaseFunctions } from "../firebase";

/** The class will be used to get all the data from the backend,
i.e. project previews, project information with steps... */

export class ApiService<Props> {
    constructor(props: Props) {}

    static async getUserProjectSummaries(): Promise<
        PublicProjectInformation[]
    > {
        let response = await firebaseFunctions.httpsCallable(
            "projects-getKidProjectsInformation"
        )({});
        return response.data;
    }

    // TODO: Pass the user information so we can get only the avatars of the current user
    static async getAllAvatars(): Promise<Array<AvatarEl>> {
        let response = await firebaseFunctions.httpsCallable(
            "getAllAvailableAvatars"
        )();
        return response.data;
    }

    static getCurrentAvatarInformation(): AvatarEl {
        return {
            id: "av1",
            url: "https://i.imgur.com/LX4cXjE.png",
            available: true,
        };
    }

    //TODO: Check where this is used and change it so that this function is only called when needed
    static async getCurrentChildName(): Promise<string> {
        let response = await firebaseFunctions
            .httpsCallable("getCurrentChildName")
            .call("idChild");
        return response.data;
    }

    static async saveAvatarImage(avatar?: AvatarEl): Promise<String> {
        let response = await firebaseFunctions.httpsCallable(
            "saveAvatarImage"
        )();

        if (response.data != "Icon updated") {
            return "Error saving the icon";
        } else return "Icon updated";
    }

    static async downloadProject(projectId: number): Promise<FullProject> {
        let response = await firebaseFunctions.httpsCallable("getFullProject")({
            projectId: projectId,
        });
        return response.data;
    }

    static async sendResult(image: string) {
        await firebaseFunctions.httpsCallable("sendSubmission")({
            image: image,
        });
    }
}
