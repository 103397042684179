import { firebaseFunctions } from "../firebase";
import { GroupPublicData } from "./Group";

export interface StoreMainPage {
    starred: Product;
    products: Product[];
}

export interface Product {
    imageUrl: string;
    title: string;
    subtitle: string;
    chips?: string[];
    distributionId: string[];
    projectId: string;
    robotics: boolean;
    programming: boolean;
    ages: [number, number];
    price: number;
    description: string;
    projectName: string;
}

export class Store {
    constructor() {}

    static async getStoreMainPage(userId: string): Promise<StoreMainPage> {
        const response = await firebaseFunctions.httpsCallable(
            "store-getStoreMainPage"
        )({ userId: userId });
        return response.data as StoreMainPage;
    }

    static async getGroupsFromProjectId(
        projectId: string
    ): Promise<GroupPublicData[]> {
        const response = await firebaseFunctions.httpsCallable(
            "groups-getGroupsPublicDataFromProjectId"
        )({
            projectId: projectId,
        });
        return response.data as GroupPublicData[];
    }
}
