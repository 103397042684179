/** Returns the base64 string of the provided file */

export async function getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result) resolve(reader.result as string);
        };
        reader.onerror = (error) => reject(error);
    });
}
