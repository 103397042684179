import { Modal, StyleSheet, View, Text, Dimensions } from "react-native";
import React from "react";
import { UIButton } from "../UI/UI";
import { LanguageIcon, LanguageService } from "../../Service/LanguageService";

const { height, width } = Dimensions.get("window");
const modalWidth = width * 0.8;
const modalHeight = height * 0.7;
const padding = 30;

export const LanguageSelection = (props: {
    possibleTextLanguages?: string[];
    possibleAudioLanguages?: string[];
    modalVisible: boolean;
    onClosed: Function;
}) => {
    const currentTextLanguage = LanguageService.getCurrentTextLanguage();
    const currentAudioLanguage = LanguageService.getCurrentAudioLanguage();

    let possibleAudioLanguagesIcons;
    if (props.possibleAudioLanguages) {
        possibleAudioLanguagesIcons = LanguageService.getLanguages(
            props.possibleAudioLanguages
        );
    }

    let possibleTextLanguagesIcons;
    if (props.possibleTextLanguages) {
        possibleTextLanguagesIcons = LanguageService.getLanguages(
            props.possibleTextLanguages
        );
    }

    function onTextButtonPressed(language: string) {
        LanguageService.setCurrentTextLanguage(language);
        props.onClosed();
    }

    function onAudioButtonPressed(language: string) {
        LanguageService.setCurrentAudioLanguage(language);
        props.onClosed();
    }

    return (
        <Modal animationType="fade" transparent visible={props.modalVisible}>
            <View style={styles.modalContainer}>
                <View style={styles.modalBox}>
                    {possibleTextLanguagesIcons ? (
                        <Row
                            text="TEXT"
                            languages={possibleTextLanguagesIcons}
                            onButtonPressed={onTextButtonPressed}
                            currentLanguage={currentTextLanguage}
                        />
                    ) : null}
                    {possibleTextLanguagesIcons ? (
                        <View
                            style={{
                                width: "100%",
                                borderBottomWidth: 2,
                                borderColor: "rgb(9,107,124)",
                            }}
                        />
                    ) : null}
                    {possibleAudioLanguagesIcons ? (
                        <Row
                            text="AUDIO"
                            languages={possibleAudioLanguagesIcons}
                            onButtonPressed={onAudioButtonPressed}
                            currentLanguage={currentAudioLanguage}
                        />
                    ) : null}
                </View>
            </View>
        </Modal>
    );
};

const Row = (props: {
    text: string;
    languages: Array<LanguageIcon>;
    onButtonPressed: Function;
    currentLanguage: string;
}) => {
    return (
        <View>
            <Text style={styles.titleText}>{props.text}</Text>
            <ButtonRow
                languages={props.languages}
                currentLanguage={props.currentLanguage}
                onButtonPressed={props.onButtonPressed}
            ></ButtonRow>
        </View>
    );
};

const ButtonRow = (props: {
    languages: LanguageIcon[];
    onButtonPressed: Function;
    currentLanguage: string;
}) => {
    const buttonSize = (modalWidth - padding * 2) / props.languages.length;
    const maxSize = height / 5;
    return (
        <View style={styles.buttonRowContainer}>
            {props.languages.map((val, ind) => {
                return (
                    <View
                        style={[
                            {
                                height: buttonSize,
                                width: buttonSize,
                                maxHeight: maxSize,
                                maxWidth: maxSize,
                            },
                            props.currentLanguage == val.language
                                ? { borderWidth: 1 }
                                : null,
                        ]}
                        key={ind}
                    >
                        <UIButton
                            image={val.image}
                            text={val.nativeName.toLocaleUpperCase()}
                            textColor={"rgb(85,85,85)"}
                            bold
                            onPress={() => props.onButtonPressed(val.language)}
                        />
                    </View>
                );
            })}
        </View>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        backgroundColor: "rgba(0,0,0,0.5)",
        justifyContent: "center",
        alignItems: "center",
    },
    modalBox: {
        height: modalHeight,
        width: modalWidth,
        backgroundColor: "white",
        padding: padding,
        justifyContent: "space-between",
        borderRadius: 30,
    },
    titleContainer: {},
    titleText: {
        textAlign: "center",
        fontFamily: "Roboto_Bold",
        fontSize: 30,
    },
    buttonRowContainer: {
        flexDirection: "row",
        marginTop: 20,
        justifyContent: "center",
    },
});
