import React from "react";
import { Loading } from "../UI/Loading";
import { FullScreenBackground } from "../UI/UI";

export const SplashScreen = () => {
    return (
        <FullScreenBackground
            source={require("../../../assets/backgrounds/children.jpg")}
        >
            <Loading fullScreen />
        </FullScreenBackground>
    );
};
