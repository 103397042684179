import React, { useState } from "react";
import {
    Dimensions,
    ImageBackground,
    ImageSourcePropType,
    StyleSheet,
    Text,
    View,
} from "react-native";
import { Avatar } from "../HomeScreen/Header/Avatar";
import { UIButton } from "../../UI/UI";
import { KidInfo } from "src/Service/User";

const { height } = Dimensions.get("window");

/** The parameters of the Project Header:
 * @param players MANDATORY An array with the name and icon of the players
 * @param headerHeight MANDATORY Since aspectRatio doesn't work on the web, we must know the size of the header
 * @param leftButton OPTIONAL the header will produce a button with the provided icon and onPress function on the left
 * @param rightButton OPTIONAL the header will produce a button with the provided icon and onPress function on the right side.
 * @param stars OPTIONAL the header will produce an icon with the number of stars provided on the right side
 * @throws There can only be a rightButton or stars, not both.
 */
interface ProjectHeaderProps {
    leftButton?: ButtonProps;
    rightButton?: ButtonProps;
    players: Array<KidInfo>;
    stars?: {
        number: number;
    };
    headerHeight: number;
}

export const ProjectHeader = (props: ProjectHeaderProps) => {
    const multiplePlayers: boolean = props.players.length > 1;

    return (
        <View style={headerStyles.headerContainer}>
            {props.leftButton ? (
                <LeftButton button={props.leftButton.button} />
            ) : null}
            {multiplePlayers ? (
                <PlayersBox
                    height={props.headerHeight}
                    players={props.players}
                />
            ) : (
                <SinglePlayer
                    height={props.headerHeight}
                    player={props.players[0]}
                />
            )}
            {props.stars ? <StarsBox number={props.stars.number} /> : null}
            {props.rightButton ? (
                <RightButton button={props.rightButton.button} />
            ) : null}
        </View>
    );
};

interface ButtonProps {
    button: {
        icon: ImageSourcePropType;
        onPressFunc: Function;
    };
}

const LeftButton = (props: ButtonProps) => {
    return (
        <View style={headerStyles.backButtonContainer}>
            <UIButton
                image={props.button.icon}
                onPress={props.button.onPressFunc}
            />
        </View>
    );
};

const RightButton = (props: ButtonProps) => {
    return (
        <View style={headerStyles.nextButtonContainer}>
            <UIButton
                image={props.button.icon}
                onPress={props.button.onPressFunc}
            />
        </View>
    );
};
const StarsBox = (props: { number: number }) => {
    return (
        <View style={[headerStyles.starsContainer]}>
            <ImageBackground
                source={require("../../../../assets/icons/star.png")}
                style={[
                    headerStyles.fill,
                    headerStyles.imageBackgroundContainer,
                ]}
            >
                <Text style={headerStyles.starsNumberText}>{props.number}</Text>
            </ImageBackground>
        </View>
    );
};

const PlayersBox = (props: { height: number; players: Array<KidInfo> }) => {
    const boxWidth = props.height * 0.85 * (props.players.length + 0.5);
    const avatarSize = props.height * 0.85;

    return (
        <View
            style={[
                {
                    width: boxWidth,
                },
                headerStyles.playersBoxContainer,
            ]}
        >
            {props.players.map((val, i) => {
                return (
                    <View
                        style={{
                            height: avatarSize,
                            width: avatarSize,
                        }}
                        key={i}
                    >
                        <Avatar
                            player={val}
                            textColor="black"
                            backgroundColor="rgba(0,0,0,0)"
                            bold={true}
                        />
                    </View>
                );
            })}
        </View>
    );
};

const SinglePlayer = (props: { height: number; player: KidInfo }) => {
    return (
        <View
            style={{
                height: props.height * 0.85,
                width: props.height * 0.85,
            }}
        >
            <Avatar player={props.player} bold={true} noText={true}></Avatar>
        </View>
    );
};

const headerStyles = StyleSheet.create({
    headerContainer: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    fill: {
        height: "100%",
        width: "100%",
    },

    backButtonContainer: {
        position: "absolute",
        left: 30,
        width: height / 8,
        height: height / 8,
    },

    nextButtonContainer: {
        position: "absolute",
        right: 30,
        width: height / 8,
        height: height / 8,
    },
    starsContainer: {
        position: "absolute",
        right: 30,
        width: height / 8,
        height: height / 8,
    },

    imageBackgroundContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    starsNumberText: {
        color: "white",
        fontFamily: "Roboto_Bold",
        fontSize: 40,
        position: "relative",
        top: 3,
        textShadowColor: "rgba(0,0,0,0.16)",
        textShadowOffset: { width: 3, height: 3 },
        textShadowRadius: 3,
    },

    playersBoxContainer: {
        height: "85%",
        backgroundColor: "rgba(255,255,255,0.9)",
        borderRadius: 40,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
    },
});
